import { InjectionKey } from "vue"
import { createStore, useStore as baseUseStore, Store } from "vuex"
import {
  RootState,
  SolarQuote,
  GCaptchaResponse,
  CustomerAccount,
} from "./untested/types"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { untestedActions, untestedGetters, untestedMutation } from "./untested"

export const getDefaultState = () =>
  ({
    quote: {
      solarPackage: null,
      solarPackageTitle: null,
      solarProductType: null,
      isExistingCustomer: null,
      isFlatRoof: null,
      isUsageExact: null,
      exactUsageKwh: null,
      estimatedUsage: { fromApi: false, value: null },
      isUnitRateExact: null,
      unitRate: { fromApi: false, value: null },
      isUnitRateNationalAverage: null,
      isInConservationArea: null,
      firstName: null,
      lastName: null,
      title: null,
      email: null,
      phone: null,
      postcode: null,
      dataConsent: null,
      propertyConfirmation: null,
      electricitySupplier: null,
      electricitySupplierTariff: null,
      whenGetSolar: null,
      whoSuggested: null,
      customerPreference: null,
      whenAtHome: null,
      roofLocation: null,
      extraInfo: "",
      overrideAddress: false,
      tariff: null,
      accountNumber: null,
      conectiaReqid: null,
      conectiaAffid: null,
    } as SolarQuote,
    currentSupplierTariff: {
      electricitySuppliersList: [],
      electricitySupplierTariffList: [],
    },
    gCaptcha: {
      gCaptchaResponse: null,
    } as GCaptchaResponse,
    customerAccount: {} as CustomerAccount,
  } as RootState)

export default createStore<RootState>({
  state: getDefaultState(),
  getters: {
    ...untestedGetters,
    // Tested getters below
  },
  mutations: {
    ...untestedMutation,
    // Tested mutations below
    SET_ADDRESS(state, address) {
      state.quote.address = address
    },
    SET_CONECTIA(state, conectiaParams) {
      state.quote.conectiaReqid = conectiaParams.conectiaReqid
      state.quote.conectiaAffid = conectiaParams.conectiaAffid
    },
  },
  actions: {
    ...untestedActions,
    // Tested actions below
    login({ state }, credentials) {
      return AuthenticationService.login(credentials)
    },
  },
  modules: {},
})

// Typescript support
// https://vuex.vuejs.org/guide/typescript-support.html#simplifying-usestore-usage
export const key: InjectionKey<Store<RootState>> = Symbol()

export function useStore() {
  return baseUseStore(key)
}
