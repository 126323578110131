<template>
  <div class="map">
    <div ref="map" class="map-inner"></div>
    <div v-if="showError" class="map-error">
      <i class="icon icon-alert"></i>
      <p>Could not load Google Maps</p>
      <slot name="mapError"></slot>
    </div>
    <div class="map-loading" :class="{ 'map-loading--active': loading }">
      <i class="icon icon-recycle"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import type { PropType } from "vue"
import GMaps from "@/services/GoogleMapsService"
import { MapLocation } from "@/store/untested/types"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

const defaultZoomFactor = 19

const defaultMapOptions = {
  center: {
    // center of the UK,
    lat: 55,
    lng: -4.5,
  },
  zoom: 5.5,
  streetViewControl: false,
  mapTypeControl: false,
  mapTypeId: "satellite",
  fullscreenControl: false,
  keyboardShortcuts: false,
  disableDoubleClickZoom: true,
  tilt: 0,
  rotateControl: false,
  gestureHandling: "auto",
  zoomControl: true,
  panControl: true,
  disableDefaultUI: true,
} as google.maps.MapOptions

export default defineComponent({
  expose: ["setMarker"],
  props: {
    address: {
      type: String,
      default: null,
    },
    location: {
      type: Object as PropType<MapLocation> | null,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["markerChange", "error", "zoomChange", "mapDrag", "addressSearch"],
  data: () => ({
    loading: true,
    gmap: {} as google.maps.Map,
    showError: false as boolean,
    marker: null as google.maps.Marker | null,
  }),
  watch: {
    location: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          const latLng = new google.maps.LatLng(newValue)
          this.setMarker(latLng)
        } else {
          this.setMarker(null)
        }
      },
    },
    address(newValue) {
      this.locateAddress(newValue)
    },
  },
  async mounted() {
    const hasMapFailed = this.checkForMapError()

    if (!hasMapFailed) {
      await this.loadMap()
      this.loading = false
    }
  },
  methods: {
    async loadMap() {
      // Google Maps global callback that is fired when it fails to authenticate API
      window["gm_authFailure"] = this.onMapInitError

      const mapEl = this.$refs.map as HTMLElement

      try {
        if (typeof google === "undefined") {
          await GMaps.load()
        }

        const mapOptions = this.getMapOptions()
        this.gmap = new google.maps.Map(mapEl, mapOptions)

        if (this.location) {
          this.setMarker(this.gmap.getCenter())
        } else if (this.address && !this.location) {
          this.locateAddress(this.address)
        }
        this.initialiseEventListeners()
      } catch (e) {
        this.onMapInitError(e)
      }
    },
    initialiseEventListeners() {
      this.gmap.addListener("zoom_changed", () => {
        this.$emit("zoomChange", this.gmap.getZoom())
      })

      this.gmap.addListener("click", (e) => {
        if (this.readonly) return
        this.setMarker(e.latLng)
        this.$emit("markerChange", e.latLng)
      })

      this.gmap.addListener("dragend", () => {
        if (this.readonly) return
        this.$emit("mapDrag", this.gmap.getCenter())
      })
    },
    getMapOptions() {
      let mapOptions = { ...defaultMapOptions }
      if (this.readonly) {
        mapOptions.gestureHandling = "none"
        mapOptions.zoomControl = false
        mapOptions.panControl = false
      }
      if (this.location) {
        mapOptions.center = this.location
        mapOptions.zoom = defaultZoomFactor
      }
      return mapOptions
    },
    setMarker(latLng) {
      if (latLng === null && this.marker) {
        this.marker.setVisible(false)
        return
      }
      if (this.marker) {
        this.marker.setPosition(latLng)
        this.marker.setVisible(true)
        return
      }

      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.gmap,
        title: "My roof",
        icon: require(`@/assets/images/pin.svg`),
      })
    },
    focusToLocation(latLng) {
      this.gmap.setCenter(latLng)
      this.gmap.setZoom(defaultZoomFactor)
    },
    findAddressLocation(address) {
      return new Promise((resolve, reject) => {
        if (Object.keys(this.gmap).length === 0) {
          return reject("Invaild Google Maps object")
        }
        this.loading = true
        let service = new google.maps.places.PlacesService(this.gmap)
        var request = {
          query: address,
          fields: ["geometry"],
        }
        service.findPlaceFromQuery(request, (results, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            results &&
            results.length &&
            results[0].geometry
          ) {
            const latLng = results[0].geometry.location as google.maps.LatLng
            resolve(latLng)
          } else {
            reject(status)
          }
          this.loading = false
        })
      })
    },
    async locateAddress(address) {
      try {
        const latLng = await this.findAddressLocation(address)
        this.focusToLocation(latLng)
        this.$emit("addressSearch", latLng)
      } catch (error) {
        this.$emit("error", error)
        ErrorReporter.report(error, {
          context: "solar map locateAddress failed",
        })
      }
    },
    checkForMapError() {
      if (window["gm_init_failure"]) {
        this.showError = true
        this.loading = false
        this.$emit("error")
        ErrorReporter.report(new Error('"solar map init failed"'), {})
        return true
      }
      return false
    },
    onMapInitError(e) {
      window["gm_init_failure"] = true
      this.showError = true
      this.loading = false
      this.$emit("error", e)
      ErrorReporter.report(e, { context: "solar map init failed" })
      console.error(e || "Google Maps failed to load")
    },
  },
})
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  width: 100%;
  &-inner {
    width: 100%;
    height: 100%;
  }
  &-error,
  &-loading {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &-error {
    background: $grey-400;
    color: $grey-700;
    font-weight: $weight-medium;

    .icon {
      font-size: $size-4;
      margin-bottom: $space-3;
    }
  }

  &-loading {
    opacity: 0;
    visibility: hidden;
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out, visibility 0s 0.3s linear;
    &--active {
      transition-delay: 0s;
      opacity: 1;
      visibility: visible;
    }
    .icon {
      font-size: $size-3;
      color: $earth-dark;
      animation: spinAround 1s infinite linear;
    }
  }

  @keyframes spinAround {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
