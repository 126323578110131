import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3873a22a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "new-address"
}
const _hoisted_2 = { class: "new-address__title h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuoteInput = _resolveComponent("QuoteInput")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageContent.postcode), 1),
        _createVNode(_component_QuoteInput, {
          variant: "primary",
          onPostcodeIsValid: _ctx.handleValidPostcode
        }, null, 8, ["onPostcodeIsValid"])
      ]))
    : _createCommentVNode("", true)
}