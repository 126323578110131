<template>
  <div>
    <div v-for="info in customerData" :key="info.label" class="data-row">
      <div v-if="info.data" class="data-row__content">
        <p>
          <span class="data-row__title">{{ info.label }}: </span>
          <span class="data-row__text"> {{ info.content }}</span>
        </p>
        <div class="icon icon-check"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

interface CustomerData {
  data: string
  label: string
  content: string
}

export default defineComponent({
  props: {
    customerData: {
      type: Array as PropType<Array<CustomerData>>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.icon-check {
  color: $green-500;
  margin: auto 0;
  padding-left: $spacing-1;
  font-weight: $weight-bold;
  font-size: $size-6;
}

.data-row {
  &__content {
    display: flex;
    padding: $spacing-3 0;
    border-bottom: 1px solid $grey-200;
    justify-content: space-between;
  }

  &__title {
    font-size: $font-size-3;
    font-weight: $weight-medium;
  }
  &__text {
    font-size: $font-size-3;
  }
}
</style>
