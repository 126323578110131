import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroBannerHomepage = _resolveComponent("HeroBannerHomepage")!
  const _component_ContentBlockList = _resolveComponent("ContentBlockList")!
  const _directive_editable = _resolveDirective("editable")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_HeroBannerHomepage, {
          heading: _ctx.hero.heading,
          subheading: _ctx.hero.subheading,
          "font-color": _ctx.hero.fontColor,
          "left-background-color": _ctx.hero.leftBackgroundColor,
          "left-image-covering-column": _ctx.hero.leftImageCoveringColumn,
          "left-background-image": 
        _ctx.hero.leftBackgroundImage ? _ctx.hero.leftBackgroundImage.filename : {}
      ,
          "right-background-color": _ctx.hero.rightBackgroundColor,
          "right-image-asset": 
        _ctx.hero.rightImageAsset ? _ctx.hero.rightImageAsset.filename : {}
      ,
          "right-static-image": _ctx.hero.rightStaticImage,
          "right-image-contained": _ctx.hero.rightImageContained,
          button: _ctx.showButton,
          "quote-input": _ctx.hero.quoteInput,
          "login-option": _ctx.loginOption,
          "banner-background-image": _ctx.hero.bannerBackgroundImage,
          "rich-heading-text": _ctx.hero.richHeadingText,
          "quote-button-variant": _ctx.hero.quoteButtonVariant,
          onValidPostcode: _ctx.handleValidPostcode
        }, null, 8, ["heading", "subheading", "font-color", "left-background-color", "left-image-covering-column", "left-background-image", "right-background-color", "right-image-asset", "right-static-image", "right-image-contained", "button", "quote-input", "login-option", "banner-background-image", "rich-heading-text", "quote-button-variant", "onValidPostcode"]), [
          [_directive_editable, _ctx.hero]
        ]),
        _createVNode(_component_ContentBlockList, {
          blocks: _ctx.pageContent.body
        }, null, 8, ["blocks"]),
        (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
          _createElementVNode("link", {
            rel: "canonical",
            href: _ctx.canonicalLink
          }, null, 8, _hoisted_2)
        ]))
      ]))
    : _createCommentVNode("", true)
}