export const headerNavItems = [
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: "/solar/so-solar",
        name: "Home",
        component: "NavItemWebsite",
      },
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false,
  },
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: "/solar/panels",
        name: "Solar Panels",
        component: "NavItemWebsite",
      },
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false,
  },
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: "/solar/battery",
        name: "Solar Batteries",
        component: "NavItemWebsite",
      },
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false,
  },
]
