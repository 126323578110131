export enum Page {
  CustomerCheck = "CustomerCheck",
  FlatRoofCheck = "FlatRoofCheck",
  FindYourRoof = "FindYourRoof",
  ExtraInfo = "ExtraInfo",
  QuoteElectricityUsage = "QuoteElectricityUsage",
  QuoteElectricityUsageEstimate = "QuoteElectricityUsageEstimate",
  QuoteElectricityUnitRate = "QuoteElectricityUnitRate",
  ConservationArea = "ConservationArea",
  QuoteSolarPackages = "QuoteSolarPackages",
  QuoteSolarProductType = "QuoteSolarProductType",
  WhenAtHome = "WhenAtHome",
  WhenGetSolar = "WhenGetSolar",
  ThankYou = "ThankYou",
  RegisterInterest = "RegisterInterest",
  SelectAddress = "SelectAddress",
  EnterPostcode = "EnterPostcode",
  RegisterForUpdates = "RegisterForUpdates",
  ContactDetails = "ContactDetails",
  YourCurrentSupplier = "YourCurrentSupplier",
  QuoteCustomerLogin = "QuoteCustomerLogin",
  QuoteCustomerDetails = "QuoteCustomerDetails",
  QuoteNewPostcode = "QuoteNewPostcode",
  QuoteNewAddress = "QuoteNewAddress",
  WarrantiesAndManuals = "WarrantiesAndManuals",
  SolarPanels = "SolarPanels",
  SolarBattery = "SolarBattery",
}
