<template>
  <QuoteSelectAddress @go-forward="nextStep" />
</template>

<script>
import { defineComponent } from "vue"
import QuoteSelectAddress from "./QuoteSelectAddress.vue"
import TrackingService from "@soenergy/frontend-library/src/services/TrackingService"

export default defineComponent({
  components: {
    QuoteSelectAddress,
  },
  methods: {
    nextStep() {
      TrackingService.sendEvent("Solar:isValidNewSelectedAddress:true")
      this.$emit("go-forward")
    },
  },
})
</script>

<style></style>
