import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import type { UtmParams } from "./UtmService"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
})

interface Config {
  headers: {
    "g-recaptcha-response": string | null
  }
}

interface Coordinates {
  longitude: number
  latitude: number
}

interface QuoteRequestPayload {
  postcode: string
  first_name: string
  last_name: string
  title: string
  email: string
  phone: string
  consent_for_data_processing: boolean
  account_number: string
  flat_roof?: boolean
  serviceable_prospect_data?: {
    address_lines: string
    is_existing_customer: boolean
    coordinates: Coordinates | null
    notes: string
    lives_in_conservation_area: boolean
    authorised_to_alter_property: boolean
    annual_elec_consumption: string
    unit_rate: string
    selected_package: string
    product_type: string | null
    installation_timeframe: string
    time_indoors: string
    tariff: string
  }
  utm?: UtmParams | null
  customer_preference: string | null
  who_suggested: string | null
  conectia_data?: {
    req_id: string | null
    aff_id: string | null
  }
}

export default {
  async createQuoteRequest(data: QuoteRequestPayload, config: Config) {
    return apiClient.post("quotes/request-solar-quote", data, config)
  },
}
