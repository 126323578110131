import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, Teleport as _Teleport, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4225ab88"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "warranty-container"
}
const _hoisted_2 = { class: "warranty-title" }
const _hoisted_3 = { class: "warranty-content" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageContent.title), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageContent.body, (content, id) => {
            return (_openBlock(), _createBlock(_component_Dropdown, {
              key: id,
              class: "warranty-dropdown",
              "dropdown-content": content
            }, null, 8, ["dropdown-content"]))
          }), 128))
        ]),
        (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
          _createElementVNode("link", {
            rel: "canonical",
            href: _ctx.canonicalLink
          }, null, 8, _hoisted_4)
        ]))
      ]))
    : _createCommentVNode("", true)
}