export default {
  async getTypeformClient() {
    return import("@typeform/embed")
  },
  async loadWidgetStyles() {
    await import("@typeform/embed/build/css/widget.css")
  },
  async loadPopupStyles() {
    await import("@typeform/embed/build/css/popup.css")
  },
  async openPopup(typeformId, options) {
    await this.loadPopupStyles()
    const Typeform = await this.getTypeformClient()
    const { toggle } = Typeform.createPopup(typeformId, options)
    toggle()
  },
  async openWidget(typeformId, options) {
    await this.loadWidgetStyles()
    const Typeform = await this.getTypeformClient()
    Typeform.createWidget(typeformId, options)
  }
}
