const POSTCODE_SEGMENTS_REGEX = /^(([A-Z]{1,2})[0-9][A-Z0-9]?) ?[0-9][A-Z]{2}$/

const getAreaCodeFromPostcode = postcode => {
  const postcodeSegments = postcode.toUpperCase().match(POSTCODE_SEGMENTS_REGEX)

  const matchNotFound = !postcodeSegments || !postcodeSegments[2]
  if (matchNotFound) return null

  return postcodeSegments[2].toLowerCase()
}

const getOutcodeFromPostCode = postcode => {
  const postcodeSegments = postcode.toUpperCase().match(POSTCODE_SEGMENTS_REGEX)

  const matchNotFound = !postcodeSegments || !postcodeSegments[1]
  if (matchNotFound) return null

  return postcodeSegments[1].toLowerCase()
}

const getAllowedOutcodesList = outcodes => {
  return outcodes.split(",").map(outcode => outcode.toLowerCase())
}

const isPostcodeSupported = (postcode, outcodes) => {
  const allowedOutcodes = getAllowedOutcodesList(outcodes)

  const areaCode = getAreaCodeFromPostcode(postcode)
  const outcode = getOutcodeFromPostCode(postcode)

  const isPostcodeSupported = allowedOutcodes.includes(areaCode)
  const isOutcodeSupported = allowedOutcodes.includes(outcode)
  return isPostcodeSupported || isOutcodeSupported
}

export { getAreaCodeFromPostcode, getOutcodeFromPostCode, isPostcodeSupported }
