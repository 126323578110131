import axios from "axios"

const apiClient = () => {
  const baseURL = process.env.VUE_APP_ACCOUNT_SITE_URL
  const header = {
    "Content-Type": "application/json"
  }

  const api = axios.create({
    baseURL,
    headers: header
  })

  return api
}

export default {
  async getForPostcode(customerPostcode, config = {}) {
    const response = await apiClient().get(
      `api/addresses?postcode=${customerPostcode}`,
      config
    )
    return response.data
  }
}
