import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"

export default async (to, from, next) => {
  const loginPage = to.meta.requiresAnon
  const authenticatedRoute = to.meta.requiresAuth
  const isAuthenticated =
    await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()

  if (
    (isAuthenticated && loginPage) ||
    (!isAuthenticated && authenticatedRoute)
  ) {
    return next("/")
  } else {
    next()
  }
}
