import { lory } from "lory.js"

export default {
  data() {
    return {
      slider: null,
      currentSlideIndex: 0,
      numberOfVisibleSlides: null
    }
  },
  methods: {
    slideElementAttributes(classNames) {
      return this.cards.map((card, index) => {
        const isSlideVisible = this.isSlideVisible(index)
        const slideAttributes = {
          class: classNames.slide
        }
        if (!isSlideVisible) {
          slideAttributes["aria-hidden"] = true
        }
        return slideAttributes
      })
    },
    areSlidesReady(classNames) {
      const slides = Array.from(
        this.$refs.slides.$el.querySelectorAll("." + classNames?.slide)
      )
      if (slides) {
        for (const slide of slides) {
          if (slide.clientWidth <= 0) {
            return false
          }
        }
      }
      return true
    },
    whenReadyInitiateCarousel(classNames) {
      const target = this.$refs.carousel
      const config = {
        attributes: true,
        childList: true,
        subtree: true
      }

      const callback = (mutationList, observe) => {
        if (this.areSlidesReady(classNames)) {
          this.initiateCarousel(classNames)
          observe?.disconnect()
        }
      }

      if (this.areSlidesReady(classNames)) {
        this.initiateCarousel(classNames)
      } else {
        const observer = new MutationObserver(callback)
        observer.observe(target, config)
      }
    },
    initiateCarousel(classNames) {
      const loaded = Boolean(this.slider)
      if (!loaded) {
        const carouselElement = this.$refs.carousel
        this.slider = lory(carouselElement, {
          enableMouseEvents: true,
          classNameFrame: classNames.wrapper,
          classNameSlideContainer: classNames.slides,
          rewind: this.rewind
        })
        carouselElement.addEventListener("after.lory.slide", () => {
          this.currentSlideIndex = this.slider.returnIndex()
          this.manageFocusableElements()
        })
        this.numberOfVisibleSlides = Math.floor(
          this.$refs.carousel.getBoundingClientRect().width / this.slideWidth()
        )
        this.manageFocusableElements()
      }
    },
    manageFocusableElements() {
      const slideElements = Array.from(this.$refs.slides.$el.children)

      slideElements.forEach((slideElement, index) => {
        const removeElementsFromKeyboardNavigation = !this.isSlideVisible(index)
        const focusableElements = Array.from(
          slideElement.querySelectorAll("a,button")
        )

        focusableElements.forEach(element => {
          if (removeElementsFromKeyboardNavigation) {
            element.setAttribute("tabindex", -1)
          } else {
            element.removeAttribute("tabindex")
          }
        })
      })
    },
    isSlideVisible(index) {
      const indexOffset = index - this.currentSlideIndex
      return indexOffset > -1 && indexOffset < this.numberOfVisibleSlides
    },
    slideWidth() {
      return this.$refs.slides.$el.children[0].clientWidth
    },
    moveLeft() {
      this.slider.prev()
    },
    moveRight() {
      this.slider.next()
    }
  }
}
