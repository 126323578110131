<template>
  <template v-if="pageContent && enableSimplePackagesPage">
    <div class="solar-packages">
      <div class="solar-packages__container">
        <h2 class="h4 solar-packages__title">{{ pageContent.title }}</h2>
        <div class="solar-packages__article-wrapper">
          <p class="solar-packages__description">
            {{ pageContent.description }}
          </p>
        </div>
        <RadioGroup v-model="solarPackage" :options="packageOptions" />
        <a class="solar-packages-info" @click="showPackagesModal = true">
          <img
            :src="require('@/assets/images/question-mark.svg')"
            class="solar-packages-info__icon"
          />
          <span class="solar-packages-info__text">
            {{ pageContent.moreInfoCopy }}</span
          >
        </a>
        <AppButton
          class="solar-packages-info__next-button"
          :disabled="!solarPackage"
          full-width
          @click="next"
        >
          <span>{{ pageContent.nextButtonCopy }}</span>
          <span
            class="solar-packages__next-button-icon icon icon-arrow-right"
          />
        </AppButton>
      </div>
      <Modal
        :is-modal-open="showPackagesModal"
        is-full-screen="true"
        @close-modal="togglePackageModal"
      >
        <template #body>
          <div
            v-for="option in pageContent.packages"
            :key="option.packageId"
            class="solar-package"
          >
            <div class="solar-package__img-container">
              <img class="solar-package__img" :src="option.image.filename" />
            </div>
            <h4 class="solar-package__title">{{ option.title }}</h4>
            <p class="solar-package__details">{{ option.aboutPackage }}</p>
          </div>
        </template>
        <template #cancelButton>{{
          pageContent.moreInfoCloseButtonCopy
        }}</template>
      </Modal>
    </div>
  </template>
  <template v-else>
    <CarouselSolarPackages @go-forward="$emit('go-forward')" />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapGetters, mapMutations } from "vuex"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup.vue"
import Modal from "@soenergy/frontend-library/src/components/Modal.vue"
import CarouselSolarPackages from "@/components/CarouselSolarPackages.vue"

import story from "soenergy-cms-loader!?path=solar/solar-packages"
import { ExtendedSolarPackages } from "../../types/SolarPackages"
import TrackingService from "@soenergy/frontend-library/src/services/TrackingService"

export default defineComponent({
  components: {
    AppButton,
    RadioGroup,
    Modal,
    CarouselSolarPackages,
  },
  mixins: [cmsPreviewMixin<ExtendedSolarPackages>({ story })],
  emits: ["go-forward"],
  data() {
    return {
      copy: {},
      showPackagesModal: false,
    }
  },
  computed: {
    packageOptions() {
      return this.pageContent.packages.map((option) => ({
        label: option.title,
        value: option.packageId,
        inputId: `input-${option.packageId}`,
        badgeContent: option.badgeContent,
      }))
    },
    solarPackage: {
      get() {
        return this.getSolarPackage
      },
      set(value) {
        this.setSolarPackage(value)
      },
    },
    ...mapGetters(["getSolarPackage"]),
    enableSimplePackagesPage() {
      return this.pageContent.showSimplePackagesPage.includes(
        process.env.TARGET_ENV
      )
    },
  },
  methods: {
    ...mapMutations({
      setSolarPackage: "SET_SOLAR_PACKAGE",
    }),
    next() {
      if (this.solarPackage) {
        TrackingService.sendEvent(`Solar:SelectedPackage:${this.solarPackage}`)
      }
      this.$emit("go-forward")
    },
    togglePackageModal() {
      this.showPackagesModal = !this.showPackagesModal
    },
  },
})
</script>

<style lang="scss" scoped>
.solar-packages {
  max-width: $width-4;
  &-info {
    display: inline-flex;
    align-items: center;
    margin: $space-5 0;
    color: $night;
    text-decoration: none;
    cursor: pointer;
    font-size: $font-size-3;

    &__text {
      margin-left: $space-2;
    }
  }

  &__next-button {
    font-size: $font-size-4;
    margin-top: $space-6;

    &-icon {
      font-weight: $weight-medium;
      margin-left: $space-2;
    }
  }

  &__description {
    font-size: $font-size-4;
    margin: $space-2 0 $space-4;
  }
}

.solar-package {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-7;
  padding: 0 $font-size-4;
  text-align: left;

  &__img {
    max-height: 144px;
    width: auto;

    &-container {
      width: 100%;
    }
  }

  &__title {
    font-size: $font-size-5;
    margin-bottom: $space-3;
    text-transform: capitalize;
  }
  &__details {
    font-size: $font-size-3;
    width: 95%;

    @include md {
      width: 100%;
    }
  }
}
</style>
