<template>
  <div v-if="pageContent" class="solar-product-types">
    <div class="solar-product-types__header">
      <h2 class="h4 solar-product-types__title">
        Choose a {{ solarPackageTitle }}
      </h2>
      <p class="solar-product-types__description">
        {{ pageContent.description }}
      </p>
    </div>
    <Carousel
      :cards="filteredProductTypes"
      :copy="{}"
      no-padding
      buttons-mobile
      margins="small"
      class="solar-product-types__carousel"
      @get-quote="next($event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/solar-product-types"
import { RootState } from "@/store/untested/types"
import Carousel from "@soenergy/frontend-library/src/components/Carousel.vue"
import { mapMutations, mapState } from "vuex"
import TrackingService from "@soenergy/frontend-library/src/services/TrackingService"

export default defineComponent({
  components: {
    Carousel,
  },
  mixins: [cmsPreviewMixin({ story })],
  emits: ["go-forward"],
  computed: {
    ...mapState({
      solarPackageId: (state: RootState) => state.quote.solarPackage,
      solarPackageTitle: (state: RootState) => state.quote.solarPackageTitle,
    }),
    filteredProductTypes() {
      return this.pageContent?.productTypes?.filter(
        (solarProductType) =>
          solarProductType.parentPackageId === this.solarPackageId
      )
    },
  },
  methods: {
    ...mapMutations({
      setSolarProductType: "SET_SOLAR_PRODUCT_TYPE",
    }),
    next(solarProductTypeId) {
      if (solarProductTypeId) {
        TrackingService.sendEvent(
          `Solar:SelectedProductType:${solarProductTypeId}`
        )
      }
      this.setSolarProductType(solarProductTypeId)
      this.$emit("go-forward")
    },
  },
})
</script>

<style lang="scss" scoped>
.solar-product-types {
  &__header {
    max-width: $width-7;
  }
  &__description {
    font-size: $font-size-4;
    margin: $space-4 0 $space-2;
  }
  &__article-wrapper {
    max-width: 550px;
  }
  &__carousel {
    padding: 0;
    margin: 0;
  }
}
</style>
