import axios from "axios"

const apiClient = () => {
  const baseURL = process.env.VUE_APP_ACCOUNT_SITE_URL
  const header = {
    "Content-Type": "application/json"
  }

  const api = axios.create({
    baseURL,
    headers: header
  })

  return api
}

export default {
  getSuppliers(data) {
    return apiClient().get(
      "api/providers?postcode=" +
        data.postcode +
        "&energy_type=" +
        data.energyType +
        "&economy_7=false"
    )
  },
  getTariffs(data) {
    return apiClient().get(
      "api/providers/" +
        data.supplierId +
        "?postcode=" +
        data.postcode +
        "&energy_type=" +
        data.energyType +
        "&economy_7=false"
    )
  }
}
