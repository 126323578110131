<template>
  <section
    v-if="pageContent"
    class="extra-info"
    :class="{
      'extra-info--show-map': showMap,
      'extra-info--has-map': hasMap,
    }"
  >
    <Card :two-column-layout="hasMap">
      <template #heading>
        <div class="find-roof__address">
          <div class="extra-info__heading">
            <img :src="require('@/assets/images/location.svg')" class="mr-2" />
            <p class="extra-info__heading-text">
              {{ getFullAddress }}
            </p>
            <a
              v-if="hasMap"
              href="javascript:;"
              class="extra-info__heading-btn"
              @click="toggleMap"
            >
              {{ toggleButtonCopy }}
            </a>
          </div>
        </div>
      </template>

      <div class="extra-info__content">
        <h1 class="extra-info__title h4">
          {{ pageContent.title }}
        </h1>

        <p class="extra-info__tip">{{ pageContent.tip }}</p>

        <InputField
          v-model="extraInfo"
          :placeholder="pageContent.textareaPlaceholder"
          :maxlength="pageContent.textareaMaxLength"
          label="Notes"
          name="notes"
          class="extra-info__textarea"
          force-label-up
          multiline
          autofocus
        ></InputField>

        <AppButton class="extra-info__button" @click="goToNextStep">
          <span>{{ pageContent.nextStepButtonCopy }}</span>
        </AppButton>
      </div>

      <template #rightColumn>
        <div v-if="hasMap" class="extra-info__map">
          <GMap
            :location="roofLocation"
            class="extra-info__map-inner"
            readonly
          ></GMap>
        </div>
      </template>
    </Card>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapGetters } from "vuex"

import toTitleCase from "@/helpers/toTitleCase"

import Card from "@/components/Card.vue"
import GMap from "@/components/GMap.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/extra-info"

export interface SolarQuoteExtraInfo {
  title: string
  tip: string
  textareaPlaceholder: string
  textareaMaxLength: number
  showMapButtonCopy: string
  hideMapButtonCopy: string
  nextStepButtonCopy: string
}

export interface Address {
  address: string
  postcode: string
}

export default defineComponent({
  components: {
    AppButton,
    InputField,
    GMap,
    Card,
  },
  mixins: [cmsPreviewMixin<SolarQuoteExtraInfo>({ story })],
  emits: ["go-forward"],
  data: () => ({
    showMap: false,
  }),
  computed: {
    ...mapGetters(["roofLocation", "address"]),
    extraInfo: {
      get() {
        return this.$store.state.quote.extraInfo
      },
      set(value) {
        this.$store.commit("SET_EXTRA_INFO", value)
      },
    },
    getFullAddress() {
      let address = this.address
      address = toTitleCase(address)
      let postcode = this.$store.state.quote.postcode as string
      postcode = postcode.toUpperCase()
      return `${address} ${postcode}`
    },
    toggleButtonCopy() {
      return this.showMap
        ? this.pageContent.hideMapButtonCopy
        : this.pageContent.showMapButtonCopy
    },
    hasMap() {
      return this.roofLocation !== null
    },
  },
  methods: {
    goToNextStep() {
      this.$emit("go-forward")
    },
    toggleMap() {
      this.showMap = !this.showMap
    },
  },
})
</script>

<style lang="scss">
.extra-info {
  &--has-map {
    max-width: 100%;
    width: $width-10;
  }
  &__title.h4 {
    text-transform: none;
  }
  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: $space-8;
    &-text {
      white-space: nowrap;
      margin-right: $space-2;
      font-weight: $weight-medium;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-btn {
      margin-left: auto;
      white-space: nowrap;
      flex-shrink: 0;
    }
  }
  &__map {
    display: none;
    align-content: stretch;
    background: $grey-400;
    margin: 0 (-$space-4) $space-8 (-$space-4);
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    &-inner {
      width: 100%;
    }
  }
  &--show-map &__map {
    display: flex;
  }
  &__button {
    margin-top: $space-8;
  }
  &__tip {
    margin-bottom: $space-8;
  }
  &__textarea {
    .form-group__input-group,
    .form-group__input {
      max-width: 100% !important;
    }
    .form-group__input {
      font-size: $font-size-2;
      height: 10em;

      &::placeholder {
        color: $black !important;
        line-height: $line-height-2;
      }
    }
  }

  @include md {
    font-size: $font-size-4;
    &__address,
    &__content {
      max-width: 360px;
    }
    &__map {
      display: flex !important;
      margin: 0;
      border-radius: $space-2;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    &__heading-btn {
      display: none;
    }
    &__textarea .form-group__input {
      height: 8em;
    }
  }
}
</style>
