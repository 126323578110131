<template>
  <VueRecaptcha
    v-if="!disabled"
    class="recaptcha-checkbox"
    :sitekey="sitekey"
    :load-recaptcha-script="true"
    @verify="handleSuccess"
    @error="handleError"
  ></VueRecaptcha>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha"

export default {
  name: "ReCaptcha",
  components: {
    VueRecaptcha
  },
  emits: ["success"],
  props: {
    sitekey: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.disabled) {
      this.handleSuccess()
    }
  },
  methods: {
    handleError() {
      throw new Error("Recaptcha failed")
    },
    handleSuccess(response) {
      this.$emit("success", response)
    }
  }
}
</script>

<style lang="scss">
.recaptcha {
  &__feedback-dialog {
    margin-top: $space-4;
  }
}
</style>
