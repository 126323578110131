export default {
  ImageGrid: () =>
    import("@soenergy/frontend-library/src/components/ImageGrid"),
  UspBlock: () => import("@soenergy/frontend-library/src/components/UspBlock"),
  UspBlockDetailed: () =>
    import("@soenergy/frontend-library/src/components/UspBlockDetailed"),
  IllustratedSteps: () =>
    import("@soenergy/frontend-library/src/components/IllustratedSteps"),
  AccordionBlock: () =>
    import("@soenergy/frontend-library/src/components/AccordionBlock"),
  SolarPackage: () => import("./SolarPackage"),
  SolarProductType: () => import("./SolarProductType"),
  InfographicBlock: () => import("./InfographicBlock"),
  SolarDoubleBlock: () => import("./SolarDoubleBlock"),
  TwoColumnBlock: () =>
    import("@soenergy/frontend-library/src/components/TwoColumnBlock"),
  TwoColumnHero: () =>
    import("@soenergy/frontend-library/src/components/TwoColumnHero"),
  Carousel: () => import("@soenergy/frontend-library/src/components/Carousel"),
  TrustpilotCard: () =>
    import("@soenergy/frontend-library/src/components/TrustpilotCard"),
  CarouselTwoColumn: () =>
    import("@soenergy/frontend-library/src/components/CarouselTwoColumn"),
  ReadMoreCard: () =>
    import("@soenergy/frontend-library/src/components/ReadMoreCard"),
  SolarCalculator: () =>
    import("@soenergy/frontend-library/src/components/SolarCalculator"),
  UspBlockNumeric: () =>
    import("@soenergy/frontend-library/src/components/UspBlockNumeric"),
}
