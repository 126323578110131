import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca13d648"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section register-interest" }
const _hoisted_2 = { class: "register-interest__container" }
const _hoisted_3 = {
  key: 0,
  class: "register-interest__title h4"
}
const _hoisted_4 = {
  ref: "typeformWrapper",
  class: "register-interest__typeform-element"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingOverlay, { "is-active": _ctx.isLoading }, null, 8, ["is-active"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.pageContent.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.pageContent.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, null, 512)
      ])
    ])
  ], 64))
}