<template>
  <div class="dropdown-container">
    <section class="dropdown-content">
      <div class="dropdown-toggle">
        <div class="dropdown-toggle__header">
          <ul>
            <li class="dropdown-toggle__list">
              <button
                class="dropdown-toggle__button"
                aria-label="dropdown"
                @click="toggleChild"
              >
                <span class="dropdown-title">{{ dropdownContent.title }}</span>
                <i
                  class="icon dropdown-toggle__icon"
                  :class="showChild ? 'icon-chevron-up' : 'icon-chevron-down'"
                ></i>
              </button>
            </li>
          </ul>
        </div>
        <div v-if="showChild" class="dropdown-toggle__content">
          <template v-if="dropdownContent.dropdown.length">
            <dropdown
              v-for="(childContent, id) in dropdownContent.dropdown"
              :key="id"
              :dropdown-content="childContent"
            />
          </template>
          <Article
            v-else-if="dropdownContent.content.length"
            :text-content="content"
            no-margin
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"

export default defineComponent({
  name: "Dropdown",
  components: {
    Article,
  },
  props: {
    dropdownContent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showChild: false,
    }
  },
  computed: {
    content() {
      return this.dropdownContent?.content[0]?.textContent
    },
  },
  methods: {
    toggleChild() {
      this.showChild = !this.showChild
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  &-title {
    font-weight: bold;
  }

  &-toggle {
    padding: $space-1;

    &__button {
      cursor: pointer;
      border: none;
      background: none;
      font-size: $font-size-2;
      @include lg {
        font-size: $font-size-5;
      }
    }

    &__icon {
      position: relative;
      top: $space-1;
      align-items: center;
      background: none;
      border: none;
      margin: 0 $space-3;
      cursor: pointer;
    }

    &__content {
      padding-left: $space-6;
      padding-top: $space-2;

      .dropdown-container {
        .dropdown-toggle__list {
          list-style-type: disc;
          display: list-item;
          margin-left: $space-5;

          @include lg {
            margin-left: $space-9;
          }
        }
      }
    }
  }
}
</style>
