<template>
  <div v-if="pageContent" class="when-at-home">
    <h1 class="h4 when-at-home__title">{{ pageContent.title }}</h1>
    <RadioGroup
      v-model="selectedOption"
      class="when-at-home__options"
      :options="whenAtHomeOptions"
    />
    <AppButton
      class="when-at-home__next-button"
      full-width
      :disabled="!selectedOption"
      size="large"
      @click="next"
    >
      <span>{{ pageContent.nextButton }}</span>
      <span
        class="icon when-at-home__next-button--icon icon-arrow-right"
      ></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin.js"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import { mapMutations, mapGetters } from "vuex"

import story from "soenergy-cms-loader!?path=solar/when-at-home"

export interface SolarWhenAtHome {
  title: string
  nextButton: string
  options: SolarWhenAtHomeOption[]
}

export interface SolarWhenAtHomeOption {
  title: string
  value: string
}

export default defineComponent({
  components: {
    RadioGroup,
    AppButton,
  },
  mixins: [cmsPreviewMixin<SolarWhenAtHome>({ story })],
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    ...mapGetters(["getWhenAtHome"]),
    whenAtHomeOptions() {
      return this.pageContent.options.map((option) => ({
        label: option.title,
        value: option.value,
        inputId: `input-${option.value}`,
      }))
    },
  },
  created() {
    this.selectedOption = this.getWhenAtHome
  },
  methods: {
    ...mapMutations({
      setWhenAtHome: "SET_WHEN_AT_HOME",
    }),
    next() {
      if (this.selectedOption) {
        this.setWhenAtHome(this.selectedOption)
        this.$emit("go-forward")
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.when-at-home {
  &__title {
    text-transform: none;
  }

  &__next-button {
    margin-top: $space-5;
    &--icon.icon {
      font-weight: $weight-medium;
      font-size: $font-size-5;
    }
  }
}
</style>
