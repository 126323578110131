import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a610f49"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "thank-you"
}
const _hoisted_2 = { class: "thank-you__text-wrapper section" }
const _hoisted_3 = { class: "thank-you__title h2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Article = _resolveComponent("Article")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_GenericImageLayout = _resolveComponent("GenericImageLayout")!
  const _component_ContentBlockList = _resolveComponent("ContentBlockList")!
  const _directive_editable = _resolveDirective("editable")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_GenericImageLayout, {
          "image-src": _ctx.pageContent.image.filename,
          "mobile-image": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.pageContent.title), 1),
              (_ctx.isAreaSupported)
                ? (_openBlock(), _createBlock(_component_Article, _mergeProps({ key: 0 }, _ctx.cmsBlockToProps(_ctx.pageContent.nextStepsForCoveredArea), { class: "thank-you__copy" }, { "variable-set": _ctx.contentVariables }), null, 16, ["variable-set"]))
                : (_ctx.isFlatRoof)
                  ? (_openBlock(), _createBlock(_component_Article, _mergeProps({ key: 1 }, _ctx.cmsBlockToProps(_ctx.pageContent.nextStepsForFlatRoof), { class: "thank-you__copy" }, { "variable-set": _ctx.contentVariables }), null, 16, ["variable-set"]))
                  : (_openBlock(), _createBlock(_component_Article, _mergeProps({ key: 2 }, _ctx.cmsBlockToProps(_ctx.pageContent.nextStepsForOutsideArea), { class: "thank-you__copy mt-7" }, { "variable-set": _ctx.contentVariables }), null, 16, ["variable-set"])),
              (_ctx.isAreaSupported)
                ? (_openBlock(), _createBlock(_component_Article, _mergeProps({ key: 3 }, _ctx.cmsBlockToProps(_ctx.pageContent.bookCallText), {
                    ref: "bookCallText",
                    class: "thank-you__copy mt-7"
                  }), null, 16))
                : _createCommentVNode("", true),
              (_ctx.isAreaSupported)
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 4,
                    ref: "bookCallLink",
                    class: "thank-you__button",
                    href: _ctx.pageContent.bookCallLink
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.pageContent.bookCallButton), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["href"]))
                : _createCommentVNode("", true)
            ])
          ], undefined, true),
          _: 1
        }, 8, ["image-src"])), [
          [_directive_editable, _ctx.pageContent]
        ]),
        _createVNode(_component_ContentBlockList, {
          blocks: _ctx.pageContent.body
        }, null, 8, ["blocks"])
      ]))
    : _createCommentVNode("", true)
}