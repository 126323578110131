<template>
  <div v-if="pageContent" class="electricity-usage">
    <h1 class="electricity-usage__title h4">
      {{ pageContent.title }}
    </h1>
    <p class="electricity-usage__description">
      {{ pageContent.description }}
    </p>
    <RadioGroup
      v-model="estimatedUsage"
      class="electricity-usage__options"
      :options="usageOptions"
    />
    <AppButton
      class="electricity-usage__next-button"
      :disabled="!estimatedUsage"
      full-width
      @click="$emit('go-forward')"
    >
      <span>{{ pageContent.nextStepButtonCopy }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations } from "vuex"

import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/usage-estimate"

export interface SolarQuoteElectricityUsageEstimate {
  title: string
  description: string
  nextStepButtonCopy: string
  usage_options: SolarQuoteElectricityUsageOption[]
}

export interface SolarQuoteElectricityUsageOption {
  title: string
  value: string
}

export default defineComponent({
  components: {
    AppButton,
    RadioGroup,
  },
  mixins: [cmsPreviewMixin<SolarQuoteElectricityUsageEstimate>({ story })],
  emits: ["go-forward"],
  ...mapMutations({ setEstimatedUsage: "SET_ESTIMATED_USAGE" }),
  computed: {
    usageOptions() {
      return this.pageContent.usage_options.map((option) => ({
        label: option.title,
        value: option.value,
        inputId: `input-${option.value}`,
      }))
    },
    estimatedUsage: {
      get() {
        return this.$store.state.quote.estimatedUsage.value
      },
      set(value) {
        this.$store.commit("SET_ESTIMATED_USAGE", { value })
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.electricity-usage {
  &__title {
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-top: $space-6;

    @include md {
      margin-top: $space-10;
    }
  }

  &__options {
    margin-top: $space-6;
  }

  &__next-button {
    margin-top: $space-3;
  }
}
</style>
