import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "find-roof__address" }
const _hoisted_2 = { class: "extra-info__heading" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "extra-info__heading-text" }
const _hoisted_5 = { class: "extra-info__content" }
const _hoisted_6 = { class: "extra-info__title h4" }
const _hoisted_7 = { class: "extra-info__tip" }
const _hoisted_8 = {
  key: 0,
  class: "extra-info__map"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_GMap = _resolveComponent("GMap")!
  const _component_Card = _resolveComponent("Card")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["extra-info", {
      'extra-info--show-map': _ctx.showMap,
      'extra-info--has-map': _ctx.hasMap,
    }])
      }, [
        _createVNode(_component_Card, { "two-column-layout": _ctx.hasMap }, {
          heading: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: require('@/assets/images/location.svg'),
                  class: "mr-2"
                }, null, 8, _hoisted_3),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getFullAddress), 1),
                (_ctx.hasMap)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:;",
                      class: "extra-info__heading-btn",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMap && _ctx.toggleMap(...args)))
                    }, _toDisplayString(_ctx.toggleButtonCopy), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          rightColumn: _withCtx(() => [
            (_ctx.hasMap)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_GMap, {
                    location: _ctx.roofLocation,
                    class: "extra-info__map-inner",
                    readonly: ""
                  }, null, 8, ["location"])
                ]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.pageContent.title), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.pageContent.tip), 1),
              _createVNode(_component_InputField, {
                modelValue: _ctx.extraInfo,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.extraInfo) = $event)),
                placeholder: _ctx.pageContent.textareaPlaceholder,
                maxlength: _ctx.pageContent.textareaMaxLength,
                label: "Notes",
                name: "notes",
                class: "extra-info__textarea",
                "force-label-up": "",
                multiline: "",
                autofocus: ""
              }, null, 8, ["modelValue", "placeholder", "maxlength"]),
              _createVNode(_component_AppButton, {
                class: "extra-info__button",
                onClick: _ctx.goToNextStep
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.pageContent.nextStepButtonCopy), 1)
                ], undefined, true),
                _: 1
              }, 8, ["onClick"])
            ])
          ], undefined, true),
          _: 1
        }, 8, ["two-column-layout"])
      ], 2))
    : _createCommentVNode("", true)
}