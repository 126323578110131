<template>
  <div v-if="pageContent" class="current-supplier">
    <h1 class="current-supplier__title h4">
      {{ pageContent.title }}
    </h1>
    <p class="current-supplier__description">
      {{ pageContent.description }}
    </p>
    <Combobox
      key="select-supplier"
      v-model="selectedElectricitySupplier"
      v-track="`Click electricity supplier`"
      class="select-supplier"
      :options="selectableOptions(listOfSuppliers)"
      :loading="elecSuppliersLoading"
      input-id="select-supplier"
      :placeholder="pageContent.supplierPlaceholder"
      no-matching-options-label="No available suppliers"
      :error-message="supplierErrorMessage"
      :has-error="supplierHasError"
    />
    <Combobox
      key="select-supplier-tariff"
      v-model="selectedElectricitySupplierTariff"
      v-track="`Click electricity tariff`"
      class="select-supplier-tariff"
      :options="selectableOptions(listOfTariffs)"
      :loading="elecSuppliersTariffsLoading"
      input-id="supplier-tariff"
      :placeholder="pageContent.tariffPlaceholder"
      no-matching-options-label="No available tariffs"
      :disabled="!selectedElectricitySupplier"
      :error-message="tariffErrorMessage"
      :has-error="tariffHasError"
    />
    <AppButton
      class="current-supplier__next-button"
      :disabled="!selectedElectricitySupplierTariff"
      full-width
      @click="next"
    >
      <span>{{ pageContent.continueButtonCopy }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations, mapGetters } from "vuex"
import Combobox from "@soenergy/frontend-library/src/components/Combobox.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import suppliersAndTariffs from "@soenergy/frontend-library/src/services/api/suppliersAndTariffs.js"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin.js"
import story from "soenergy-cms-loader!?path=solar/your-current-supplier"

export interface SolarYourCurrentSupplier {
  title: string
  description: string
  supplierPlaceholder: string
  supplierError: string
  tariffPlaceholder: string
  tariffError: string
  continueButtonCopy: string
}

export default defineComponent({
  components: {
    Combobox,
    AppButton,
  },
  mixins: [cmsPreviewMixin<SolarYourCurrentSupplier>({ story })],
  props: {
    postcode: {
      type: String,
      default: "W4 5PY",
      // default: "",
    },
  },
  data() {
    return {
      supplierErrorMessage: "",
      supplierHasError: false,
      tariffErrorMessage: "",
      tariffHasError: false,
    }
  },
  computed: {
    ...mapGetters([
      "electricitySupplier",
      "electricitySupplierTariff",
      "electricitySuppliersList",
      "electricitySupplierTariffList",
    ]),
    listOfSuppliers() {
      return this.electricitySuppliersList
    },
    listOfTariffs() {
      return this.electricitySupplierTariffList
    },
    selectedElectricitySupplier: {
      get() {
        return this.electricitySupplier
      },
      set(supplier) {
        this.setElecSupplier(supplier)
        this.setElecSupplierTariff(null)
        this.setElecSuppliersTariffsList([])
        if (supplier) {
          this.getTariffs("electricity", supplier.value)
          this.supplierErrorMessage = ""
          this.supplierHasError = false
        } else {
          this.supplierErrorMessage = this.pageContent.supplierError
          this.supplierHasError = true
        }
      },
    },
    selectedElectricitySupplierTariff: {
      get() {
        return this.electricitySupplierTariff
      },
      set(tariff) {
        this.setElecSupplierTariff(tariff)
        if (tariff) {
          this.tariffErrorMessage = ""
          this.tariffHasError = false
        } else {
          this.tariffErrorMessage = this.pageContent.tariffError
          this.tariffHasError = true
        }
      },
    },
    elecSuppliersLoading() {
      return !this.listOfSuppliers.length && !this.selectedElectricitySupplier
    },
    elecSuppliersTariffsLoading() {
      return (
        this.selectedElectricitySupplier &&
        !this.listOfTariffs.length &&
        !this.selectedElectricitySupplierTariff
      )
    },
  },
  created() {
    if (!this.selectedElectricitySupplier) {
      this.getSuppliers("electricity")
    }
  },
  methods: {
    ...mapMutations({
      setElecSupplier: "SET_ELECTRICITY_SUPPLIER",
      setElecSupplierTariff: "SET_ELECTRICITY_SUPPLIER_TARIFF",
      setElecSuppliersList: "SET_ELECTRICITY_SUPPLIER_LIST",
      setElecSuppliersTariffsList: "SET_ELECTRICITY_SUPPLIER_TARIFF_LIST",
    }),
    next() {
      this.$emit("go-forward")
    },
    getSuppliers(energyType) {
      const data = {
        postcode: this.postcode,
        energyType,
      }
      suppliersAndTariffs
        .getSuppliers(data)
        .then((response) => {
          if (response) {
            this.setElecSuppliersList(response.data)
          } else {
            throw new Error("When fetching suppliers, response was empty")
          }
        })
        .catch((error) => {
          console.log("There was an error", error)
          throw error
        })
    },
    getTariffs(energyType, supplierId) {
      const data = {
        postcode: this.postcode,
        energyType,
        supplierId,
      }
      suppliersAndTariffs
        .getTariffs(data)
        .then((response) => {
          if (response) {
            this.setElecSuppliersTariffsList(response.data)
          } else {
            throw new Error("When fetching tariffs, response was empty")
          }
        })
        .catch((error) => {
          console.log("There was an error", error)
          throw new Error("Failed to fetch tariffs")
        })
    },
    selectableOptions(data) {
      return data.map((item) => {
        return { label: item.name, value: item.id }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.current-supplier {
  &__title {
    margin-bottom: $space-10;
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }

  &__next-button {
    margin-top: $space-6;
  }
}
</style>
