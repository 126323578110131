import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dfe08b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step-wizard" }
const _hoisted_2 = { class: "step-wizard__wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "step-wizard__title h4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!_ctx.hideProgressBar)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          class: "step-wizard__progress-indicator",
          steps: _ctx.steps,
          "current-step-number": _ctx.currentStepNumber,
          "aria-label": _ctx.progressBarAriaLabel
        }, null, 8, ["steps", "current-step-number", "aria-label"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hideBackButton)
        ? (_openBlock(), _createBlock(_component_BackLink, {
            key: 0,
            class: "step-wizard__back-link",
            "disable-router-back": "",
            onGoBackTo: _ctx.goToPreviousStep
          }, null, 8, ["onGoBackTo"]))
        : _createCommentVNode("", true),
      (!_ctx.hideTitle && _ctx.isStepGiven)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.steps[_ctx.currentStepNumber].title), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, {
        onGoBack: _ctx.goToPreviousStep,
        onGoForward: _ctx.goToNextStep,
        onGoSpecific: _ctx.goToSpecificStep
      }, null, 8, ["onGoBack", "onGoForward", "onGoSpecific"])
    ])
  ]))
}