<template>
  <div v-if="pageContent" class="electricity-usage">
    <h1 class="electricity-usage__title h4">
      {{ pageContent.title }}
    </h1>
    <p class="electricity-usage__description">
      {{ pageContent.description }}
    </p>
    <RadioGroup v-model="isUsageExact" class="electricity-usage__usage-type">
      <RadioButton
        :value="true"
        label="Enter exact amount"
        input-id="is-usage-exact"
      />
      <InputField
        v-if="isUsageExact"
        v-model="exactUsageKwh"
        :has-error="isUsageOutOfRange"
        :error-message="usageErrorMessage"
        label="Amount (kWh)"
        name="exact-usage-kwh"
        type="number"
        required
      />
      <RadioButton
        :value="false"
        label="Help me estimate"
        input-id="is-usage-estimated"
      />
    </RadioGroup>
    <AppButton
      class="electricity-usage__next-button"
      :disabled="!usageSelected"
      full-width
      @click="next"
    >
      <span>{{
        isUsageExact
          ? pageContent.nextStepButtonCopy
          : pageContent.helpEstimateButtonCopy
      }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue"
import { useStore } from "@/store"

import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup.vue"
import RadioButton from "@soenergy/frontend-library/src/components/RadioButton/RadioButton.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import { Page } from "@/router/types"
import story from "soenergy-cms-loader!?path=solar/electricity-usage"

const MIN_USAGE = 1000
const MAX_USAGE = 40000
const USAGE_ERROR_MESSAGE = `Please enter a number between ${MIN_USAGE}kwh and ${MAX_USAGE}kwh`

export interface SolarQuoteElectricityUsage {
  title: string
  description: string
  helpEstimateButtonCopy: string
  nextStepButtonCopy: string
}

export default defineComponent({
  components: {
    AppButton,
    InputField,
    RadioButton,
    RadioGroup,
  },
  mixins: [cmsPreviewMixin<SolarQuoteElectricityUsage>({ story })],
  emits: ["go-forward", "go-specific"],
  setup(props, ctx) {
    const store = useStore()
    const isUsageExact = computed({
      get() {
        return store.state.quote.isUsageExact
      },
      set(value) {
        store.commit("SET_IS_USAGE_EXACT", value)
      },
    })
    const exactUsageKwh = computed({
      get() {
        return store.state.quote.exactUsageKwh
      },
      set(value) {
        store.commit("SET_EXACT_USAGE_KWH", value)
      },
    })
    const isUsageOutOfRange = computed(() => {
      if (exactUsageKwh.value === null) return
      const usage = Number(exactUsageKwh.value)
      return usage < MIN_USAGE || usage > MAX_USAGE
    })
    const usageErrorMessage = computed(() => {
      return isUsageOutOfRange.value ? USAGE_ERROR_MESSAGE : null
    })
    const usageSelected = computed(() => {
      return (
        isUsageExact.value === false ||
        (isUsageExact.value &&
          !!exactUsageKwh.value &&
          !isUsageOutOfRange.value)
      )
    })
    const next = () => {
      ctx.emit("go-forward")
    }
    return {
      isUsageExact,
      exactUsageKwh,
      usageSelected,
      isUsageOutOfRange,
      usageErrorMessage,
      next,
    }
  },
})
</script>

<style lang="scss" scoped>
.electricity-usage {
  &__title {
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-top: $space-6;

    @include md {
      margin-top: $space-10;
    }
  }

  &__usage-type {
    margin-top: $space-6;
  }

  &__next-button {
    margin-top: $space-3;
  }
}
</style>
