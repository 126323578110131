import commonSettings from "soenergy-cms-loader!?path=common/settings&force_prerender=true"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import router from "../router/index"
import { Page } from "../router/types"
import {
  isPostcodeSupported,
  getAreaCodeFromPostcode,
} from "@soenergy/frontend-library/src/helpers/postCodeHelper"

const nextStepIfPostCodeSupported = {
  "/": Page.SelectAddress,
  "/enter-postcode": Page.SelectAddress,
  "/solar-panels": Page.SelectAddress,
  "/solar-battery": Page.SelectAddress,
  "/faq": Page.SelectAddress,
  "/new-postcode": Page.QuoteNewAddress,
  "/customer-details": Page.FindYourRoof,
  "/new-address": Page.FindYourRoof,
}

const getCommonSettings = () => (commonSettings as any).content

const handleMissingAreaCode = (postcode) => {
  ErrorReporter.report(new Error(`Invalid postcode format`), {
    postcode,
  })
  return router.push("/")
}
const nextStep = () => {
  let currentRoute = window.location.pathname.replace(process.env.BASE_URL, "/")
  if (currentRoute.length > 1 && currentRoute.endsWith("/")) {
    currentRoute = currentRoute.slice(0, -1)
  }
  if (currentRoute) return nextStepIfPostCodeSupported[currentRoute]
}

const serveUserBasedOnPostcode = (postcode) => {
  const areaCode = getAreaCodeFromPostcode(postcode)
  if (!areaCode) {
    handleMissingAreaCode(postcode)
  }

  const supportedPostcode = isPostcodeSupported(
    postcode,
    getCommonSettings().allowedOutcodes
  )

  const isTypeformBasedFlowOn =
    getCommonSettings().isTypeformBasedFlowOn.includes(process.env.TARGET_ENV)
  if (isTypeformBasedFlowOn) {
    router.push({
      name: Page.RegisterInterest,
      query: { covered: supportedPostcode },
    })
  } else {
    router.push({
      name: supportedPostcode ? nextStep() : Page.RegisterForUpdates,
    })
  }
}

export default serveUserBasedOnPostcode
