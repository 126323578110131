import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a12f918c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card__heading"
}
const _hoisted_2 = {
  key: 1,
  class: "card__right-col"
}
const _hoisted_3 = { class: "card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'card--two-cols': _ctx.twoColumnLayout }])
  }, [
    (_ctx.$slots.heading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "heading", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.twoColumnLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "rightColumn", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}