<template>
  <section :id="sectionId" class="hero-homepage">
    <div
      class="columns"
      :style="[
        overlapText ? leftSectionStyle : {},
        bannerBackgroundImage ? bannerBackgroundImageStyle : {}
      ]"
      :class="{
        'hero-homepage__container-mobile': rightImageStackTopOnMobile
      }"
    >
      <div
        class="hero-homepage__left-column column is-7-desktop"
        :class="{
          'hero-homepage__left-column--bg-cover': leftImageCoveringColumn,
          'hero-homepage__left-column--overlap-text': overlapText,
          'is-12-tablet': !rightImageStackTopOnMobile
        }"
        :style="overlapText ? {} : leftSectionStyle"
      >
        <div class="hero-homepage__heading-container">
          <Article
            v-if="richHeadingText"
            :text-content="richHeadingText"
            class="hero-homepage__heading"
            :class="{
              'hero-homepage__heading--lowercase': lowercaseHeading,
              'hero-homepage__heading--small': smallHeading
            }"
          />
          <h1
            v-else
            class="hero-homepage__heading"
            :class="{
              'hero-homepage__heading--lowercase': lowercaseHeading,
              'hero-homepage__heading--small': smallHeading
            }"
          >
            {{ heading }}
          </h1>
          <p v-if="subheading" class="hero-homepage__text mt-1">
            {{ subheading }}
          </p>
          <div
            v-if="headingListItems && headingListItems.length > 0"
            class="ordered-list"
          >
            <ol style="list-style: none;" class="hero-homepage__list-items">
              <OrderedListItem
                v-for="(item, index) in headingListItems"
                :key="index"
                :list-index="index"
                :block="item"
                :list-item-image="item.listItemImage"
              />
            </ol>
          </div>

          <div
            class="hero-homepage__quote-container"
            :class="{
              'hero-homepage__quote-container-overlap': overlapText
            }"
          >
            <div class="hero-homepage__quote-container__input-wrapper">
              <AppButton
                v-if="buttonProps"
                :tracking="buttonProps.tracking"
                v-bind="buttonProps"
              >
                {{ buttonProps.label }}
              </AppButton>
              <QuoteInput
                v-else
                :variant="quoteButtonVariant"
                v-bind="quoteInput[0]"
                @postcode-is-valid="handleValidPostcode"
              />
              <AppButton
                v-if="showBookACall"
                size="large"
                variant="secondary"
                class="hero-homepage__quote-container__book-a-call-btn"
                @click="handleBookACall"
                >Book a call</AppButton
              >
            </div>
            <Article
              v-if="loginOption"
              ref="loginOptionText"
              :text-content="loginOption"
              class="hero-homepage__login-text"
              no-margin
            />
          </div>
        </div>
      </div>
      <div
        class="hero-homepage__right-column column is-5-desktop"
        :class="{
          'hero-homepage__right-column-overlap': overlapText,
          'hero-homepage__right-column-stack-top-mobile': rightImageStackTopOnMobile
        }"
        :style="overlapText ? {} : rightSectionStyle"
      >
        <div
          v-if="rightImageAsset"
          ref="soeAnimation"
          class="hero-homepage__animation"
          :class="{ 'hero-homepage__img-contained': rightImageContained }"
        ></div>
        <img
          v-else-if="rightStaticImage && rightStaticImage.filename"
          class="hero-homepage__static-img"
          :class="{
            'hero-homepage__static-img-large': rightImageLarge,
            'hero-homepage__full-size-img': rightImageFullSize
          }"
          :src="rightStaticImage.filename"
          :alt="rightStaticImage.alt"
        />
      </div>
    </div>
  </section>
</template>

<script>
import LottieWeb from "../services/LottieWeb.js"
import CalendlyService from "../services/CalendlyService.js"
import QuoteInput from "./QuoteInput"
import AppButton from "./AppButton"
import Article from "./Article.vue"
import OrderedListItem from "./OrderedListItem"
import stubDirectives from "../mixins/stubDirectives"
import formatStoryblokAssetUrl from "../helpers/formatStoryblokAssetUrl"

export default {
  components: {
    QuoteInput,
    AppButton,
    Article,
    OrderedListItem
  },
  mixins: [stubDirectives],
  props: {
    heading: {
      required: true,
      type: String
    },
    richHeadingText: {
      type: Object,
      default: null
    },
    subheading: {
      type: String,
      default: null
    },
    fontColor: {
      type: String,
      default: null
    },
    leftBackgroundColor: {
      type: String,
      default: null
    },
    leftBackgroundImage: {
      type: Object,
      default: null
    },
    leftImageCoveringColumn: {
      type: Boolean,
      default: false
    },
    bannerBackgroundImage: {
      type: Object,
      default: null
    },
    rightBackgroundColor: {
      type: String,
      default: null
    },
    rightImageAsset: {
      type: Object,
      default: null
    },
    rightStaticImage: {
      type: Object,
      default: null
    },
    rightImageContained: {
      type: Boolean,
      default: false
    },
    button: {
      type: Array,
      default: () => []
    },
    quoteInput: {
      type: Array,
      default: () => [{}]
    },
    loginOption: {
      type: Object,
      default: null
    },
    overlapText: {
      type: Boolean,
      default: false
    },
    rightImageLarge: {
      type: Boolean,
      default: false
    },
    lowercaseHeading: {
      type: Boolean,
      default: false
    },
    smallHeading: {
      type: Boolean,
      default: false
    },
    quoteButtonVariant: {
      type: String,
      default: null
    },
    rightImageStackTopOnMobile: {
      type: Boolean,
      default: false
    },
    rightImageFullSize: {
      type: Boolean,
      default: false
    },
    headingListItems: {
      type: Array,
      default: () => []
    },
    sectionId: {
      type: String,
      default: ""
    },
    showBookACall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonProps() {
      const buttonBlock = this.button && this.button[0]
      if (buttonBlock) {
        const { _uid, component, ...props } = buttonBlock
        return props
      }
      return null
    },
    leftSectionStyle() {
      return {
        color: this.fontColor,
        backgroundColor: this.leftBackgroundColor,
        backgroundImage: `url(${this.leftBackgroundImage &&
          this.leftBackgroundImage.filename})`
      }
    },
    rightSectionStyle() {
      return {
        backgroundColor: this.rightBackgroundColor
      }
    },
    bannerBackgroundImageStyle() {
      return {
        backgroundImage: `url(${this.bannerBackgroundImage &&
          this.bannerBackgroundImage.filename})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }
    }
  },
  async mounted() {
    if (screen.width >= 769) {
      const animationUrl = formatStoryblokAssetUrl(
        this.rightImageAsset && this.rightImageAsset.filename
      )

      const lottie = await LottieWeb.getLottieLight()
      this.anim = lottie.loadAnimation({
        container: this.$refs.soeAnimation,
        render: "svg",
        loop: 1,
        autoplay: true,
        path: animationUrl
      })
    }
  },
  methods: {
    handleValidPostcode(data) {
      const validPostcode = data.customerPostcode
      this.$emit("valid-postcode", validPostcode)
    },
    handleBookACall() {
      CalendlyService.openPopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-homepage {
  &__container-mobile {
    display: grid;
  }

  overflow: hidden;

  .columns {
    margin: 0;
  }

  &__heading-container {
    @include xxl {
      max-width: 740px;
      margin-right: 18%;
      margin-left: auto;
      align-self: center;
    }
  }

  &__heading {
    max-width: 400px;
    &--lowercase {
      text-transform: unset;
      font-weight: $weight-medium;
    }
    &--small {
      font-size: $size-3;
      @include sm {
        font-size: 4.3rem;
      }
    }
    @include lg {
      max-width: 580px;
    }
  }

  &__text {
    max-width: 350px;

    @include sm {
      font-size: $size-6;
    }

    @include lg {
      max-width: 514px;
    }
  }

  &__quote-container {
    margin-top: $spacing-6;

    &-overlap {
      padding-top: $space-25;

      @include md {
        padding-top: $space-19;
      }
      @include lg {
        padding: 0;
      }
    }

    &__input-wrapper {
      display: flex;
      flex-direction: column;

      @include lg {
        flex-direction: row;
      }
    }

    &__book-a-call-btn {
      width: fit-content;
      margin-top: $space-5;
      margin-left: 0;

      @include lg {
        margin-top: 0;
        margin-left: $space-4;
      }
    }
  }

  &__left-column {
    display: flex;
    padding: $spacing-7 $section-horizontal-padding $spacing-8;

    @include xs {
      padding-top: $spacing-8;
    }

    @include sm {
      background-size: cover;
      background-position: right;
      padding-left: $section-horizontal-padding-tablet;
      padding-top: 3rem;
    }

    @include lg {
      padding: $spacing-8 $section-horizontal-padding $spacing-9 $spacing-6;
    }

    @include xl {
      background-position: right bottom;
      background-repeat: no-repeat;
    }

    @media (max-width: #{$screen-sm-min}) {
      background-image: unset !important;
    }

    &:not(&--bg-cover) {
      @include xl {
        background-size: contain;
      }

      @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min}) {
        background-image: unset !important;
      }
    }

    &--overlap-text {
      position: relative;
      z-index: 1;
    }
  }

  &__right-column {
    display: none;
    text-align: center;
    padding: 0;

    &-stack-top-mobile {
      display: block;
      @media (max-width: #{$screen-md-min - 1}) {
        order: -1;
        display: block;
      }
    }

    @include lg {
      display: block;
    }

    &-overlap {
      position: absolute;
      display: block;
      right: 0;
      top: 4rem;
      width: 100%;
      max-width: 800px;
      padding-bottom: $space-19;
      overflow: hidden;

      @include lg {
        padding-bottom: 0;
        position: static;
      }
    }
  }

  &__animation {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @include xl {
      height: 602px;
    }
  }

  &__static-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 480px;
    padding: $spacing-6;

    &-large {
      right: 0;
      width: 100%;
      max-width: 400px;
      padding: $space-14 0 0 $space-13;
      scale: 1.5;
      margin: 0;
      float: right;

      @include lg {
        scale: 1;
        max-width: 800px;
        padding: $spacing-3 0;
      }

      @include xl {
        padding: 0;
      }
    }
  }

  &__full-size-img {
    max-width: 100%;
    padding: 0;
  }

  &__img-contained {
    @include lg {
      max-width: 408px;
      max-height: 408px;
      margin-top: 6rem;
    }
  }

  &__login-text {
    margin-top: $spacing-4;
    font-size: $font-size-2;

    @include lg {
      margin-top: $spacing-5;
      font-size: $font-size-3;
    }
  }
}
</style>
