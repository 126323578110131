<template>
  <div v-if="pageContent" class="conservation-area">
    <h1 class="conservation-area__title h4">
      {{ pageContent.title }}
    </h1>
    <RadioGroup v-model="isInConservationArea" :options="options" />
    <a class="conservation-area-info" href="javascript:;" @click="toggleModal">
      <i class="conservation-area-info__icon icon icon-tooltip-black" />
      <span class="conservation-area-info__text">
        {{ pageContent.moreInfoCopy }}
      </span>
    </a>

    <AppButton
      :disabled="isInConservationArea === null"
      class="conservation-area__next-button"
      full-width
      @click="$emit('go-forward')"
    >
      <span>{{ pageContent.continueButtonCopy }}</span>
      <span class="continue-button__icon icon icon-arrow-right" />
    </AppButton>
    <Modal
      class="conservation-area-modal"
      :is-modal-open="isModalOpen"
      @closeModal="toggleModal"
    >
      <template #header>
        <h5 class="conservation-area-modal__title">
          {{ pageContent.moreInfoModalTitle }}
        </h5>
      </template>
      <template #body>
        <Article
          class="conservation-area-modal__copy"
          :text-content="pageContent.moreInfoModalCopy"
          no-margin
        />
      </template>
      <template #cancelButton>
        {{ pageContent.moreInfoModalCloseButtonCopy }}
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue"
import { useStore } from "@/store"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import { RadioGroup } from "@soenergy/frontend-library/src/components/RadioButton"
import Modal from "@soenergy/frontend-library/src/components/Modal.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"

import story from "soenergy-cms-loader!?path=solar/conservation-area"

export interface SolarQuoteConservationArea {
  title: string
  moreInfoCopy: string
  continueButtonCopy: string
  moreInfoModalTitle: string
  moreInfoModalCopy: object
  moreInfoModalCloseButtonCopy: string
}

export default defineComponent({
  emits: ["go-forward"],
  setup() {
    const store = useStore()
    const isInConservationArea = computed({
      get() {
        return store.state.quote.isInConservationArea
      },
      set(value) {
        store.commit("SET_CONSERVATION_AREA", value)
      },
    })
    const options = [
      {
        label: "Yes",
        value: true,
        inputId: "conservation-area-yes",
      },
      {
        label: "No",
        value: false,
        inputId: "conservation-area-no",
      },
    ]
    const isModalOpen = ref(false)
    const toggleModal = () => {
      isModalOpen.value = !isModalOpen.value
    }
    return {
      isModalOpen,
      isInConservationArea,
      options,
      toggleModal,
    }
  },
  mixins: [cmsPreviewMixin<SolarQuoteConservationArea>({ story })],
  components: {
    AppButton,
    RadioGroup,
    Modal,
    Article,
  },
})
</script>

<style scoped lang="scss">
.conservation-area {
  &__title {
    text-transform: none;
  }

  &__next-button {
    margin-top: $space-6;

    &__icon {
      margin-left: $space-2;
    }
  }
}

.conservation-area-info {
  display: inline-flex;
  align-items: center;
  margin-top: $space-5;
  cursor: pointer;
  color: $night;

  &:hover {
    text-decoration: none;
  }

  &__icon {
    margin-bottom: -2px;
  }

  &__text {
    margin-left: $space-2;

    @include lg {
      font-size: $font-size-2;
    }
  }
}

.conservation-area-modal {
  &__title {
    font-weight: $weight-medium;
  }

  &__copy {
    font-size: $font-size-3;
  }
}
</style>
