<template>
  <span>
    <slot name="reference" @click="close"></slot>
    <div
      v-show="showTooltip"
      id="tooltip"
      ref="tooltip"
      class="tooltip"
      :class="{
        'tooltip--info': variant === 'info',
        'tooltip--with-boundary': !!boundaryElement
      }"
      :aria-hidden="showTooltip ? 'false' : 'true'"
    >
      <div
        v-if="variant !== 'info'"
        class="tooltip__arrow"
        data-popper-arrow
        :style="arrowStyle"
      />
      <div
        class="tooltip__inner"
        :style="tooltipStyle"
        :class="[
          {
            'tooltip__inner--info': variant === 'info',
            'tooltip__inner--got-it-button': gotItButton
          }
        ]"
      >
        <button
          v-if="!gotItButton"
          class="tooltip__close-button"
          aria-label="close"
          @click="close"
        >
          <i class="tooltip__close-button-icon icon icon-close"></i>
        </button>
        <div v-if="tooltipTitle" class="tooltip__title">
          <slot name="title">{{ tooltipTitle }}</slot>
        </div>
        <div>
          <slot>{{ tooltipContent }}</slot>
        </div>
        <AppButton
          v-if="gotItButton"
          ref="got-it-button"
          :variant="
            variant === 'info' ? 'invertedSecondary' : 'invertedPrimary'
          "
          class="tooltip__got-it-button"
          @click="close"
        >
          {{ gotItButton }}
        </AppButton>
      </div>
    </div>
  </span>
</template>

<script>
import { createPopper } from "@popperjs/core/lib/popper-lite"
import arrow from "@popperjs/core/lib/modifiers/arrow"
import offset from "@popperjs/core/lib/modifiers/offset"
import flip from "@popperjs/core/lib/modifiers/flip"
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow"
import AppButton from "./AppButton"

export default {
  components: {
    AppButton
  },
  props: {
    tooltipPlacement: {
      type: String,
      default: null
    },
    tooltipTitle: {
      type: String,
      default: null
    },
    tooltipContent: {
      type: String,
      default: null
    },
    reference: {
      type: String,
      default: null
    },
    boundary: {
      type: String,
      default: null
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: null
    },
    fontColor: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: null
    },
    gotItButton: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      referenceElement: null,
      boundaryElement: null,
      tooltip: null,
      popperJS: null,
      showTooltip: true,
      appendedToBody: false,
      placement: this.tooltipPlacement || "bottom"
    }
  },
  computed: {
    options() {
      return {
        placement: this.variant === "info" ? "right-start" : this.placement,
        modifiers: [
          arrow,
          offset,
          preventOverflow,
          flip,
          {
            name: "offset",
            options: {
              offset: ({ placement }) => {
                if (this.variant === "info" && placement === "right-start") {
                  return [0, 0]
                } else if (this.variant === "info" && placement === "bottom") {
                  return [0, 16]
                } else {
                  return [0, 30]
                }
              }
            }
          },
          {
            name: "arrow",
            options: {
              padding: 40
            }
          },
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"]
            }
          },
          {
            name: "preventOverflow",
            options: {
              boundary: this.boundaryElement
            }
          }
        ]
      }
    },
    tooltipStyle() {
      const style = {
        color: this.fontColor
      }
      return style
    },
    arrowStyle() {
      const style = {
        color: this.backgroundColor
      }
      return style
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.boundaryElement = this.boundary
        ? document.querySelector(this.boundary)
        : null
      const reference = this.reference
        ? document.querySelector(this.reference)
        : null
      const slotReference =
        this.$slots && this.$slots.reference
          ? this.$slots.reference[0].elm
          : null
      this.referenceElement = reference || slotReference
      this.tooltip = this.$refs.tooltip
      if (this.referenceElement && this.tooltip) {
        this.createPopper()
      }
    })
  },
  [process.env.VUE_APP_VERSION === "3" ? "unmounted" : "destroyed"]() {
    this.destoryPopper()
  },
  methods: {
    createPopper() {
      this.$nextTick(() => {
        this.referenceElement.setAttribute("aria-describedby", "tooltip")
        if (this.appendToBody && !this.appendedToBody) {
          this.appendedToBody = true
          document.body.appendChild(this.tooltip)
        }
        this.popperJS = createPopper(
          this.referenceElement,
          this.tooltip,
          this.options
        )
      })
    },
    destoryPopper() {
      this.showTooltip = false
      if (this.popperJS) {
        this.popperJS.destroy()
        this.popperJS = null
      }
      if (this.appendedToBody) {
        this.appendedToBody = false
        document.body.removeChild(this.tooltip)
      }
    },
    close() {
      this.$emit("close")
      this.showTooltip = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  display: inline-block;
  position: absolute;
  padding: 0 $space-4;
  z-index: zindex(tooltip);
  width: auto;

  &--info {
    max-width: 19rem;
  }

  &--with-boundary {
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    font-weight: $weight-medium;
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: $space-6;
    height: $space-6;
    border-left: 1px solid $sun;
    border-top: 1px solid $sun;
    z-index: zindex(tooltip) + 1;
    background-color: $yellow-100;
  }

  &__arrow {
    visibility: hidden;
  }

  &__arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  &__inner {
    position: relative;
    padding: $space-6 $space-4 $space-4 $space-4;
    background-color: $yellow-100;
    border: 1px solid $sun;
    border-radius: 8px;
    font-size: $size-7;
    font-weight: normal;
    text-align: left;
    line-height: $line-height-4;

    &--got-it-button {
      padding: $space-4 $space-4 $space-4 $space-4;
    }

    &--info {
      padding: $space-6 $space-6 $space-4 $space-4;
      background-color: $black;
      color: $white;
      border-radius: $space-1;
      border: none;
    }
  }

  &__close-button {
    position: absolute;
    right: $space-1;
    top: $space-2;
    border: 0;
    width: $space-6;
    height: $space-6;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: $font-size-2;
    border-radius: 4px;
    color: inherit;
  }

  &__got-it-button {
    padding: $space-1 $space-3;
    height: auto;
    font-size: $font-size-2;
    margin-top: $space-3;
  }

  &__close-button-icon {
    font-weight: bold;
  }

  &[data-popper-placement^="bottom"] .tooltip__arrow {
    top: -13px;
  }

  &[data-popper-placement^="right"] .tooltip__arrow {
    left: 3px;
  }
  &[data-popper-placement^="right"] .tooltip__arrow::before {
    transform: rotate(315deg);
  }

  &[data-popper-placement^="left"] .tooltip__arrow {
    right: 5px;
  }
  &[data-popper-placement^="left"] .tooltip__arrow::before {
    transform: rotate(135deg);
  }

  &[data-popper-placement^="top"] .tooltip__arrow {
    bottom: -11px;
  }
  &[data-popper-placement^="top"] .tooltip__arrow::before {
    transform: rotate(225deg);
  }
}
</style>
