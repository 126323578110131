<template>
  <div v-if="pageContent">
    <HeroBannerHomepage
      v-editable="hero"
      :heading="hero.heading"
      :subheading="hero.subheading"
      :font-color="hero.fontColor"
      :left-background-color="hero.leftBackgroundColor"
      :left-image-covering-column="hero.leftImageCoveringColumn"
      :left-background-image="
        hero.leftBackgroundImage ? hero.leftBackgroundImage.filename : {}
      "
      :right-background-color="hero.rightBackgroundColor"
      :right-image-asset="
        hero.rightImageAsset ? hero.rightImageAsset.filename : {}
      "
      :right-static-image="hero.rightStaticImage"
      :right-image-contained="hero.rightImageContained"
      :button="showButton"
      :quote-input="hero.quoteInput"
      :login-option="loginOption"
      :banner-background-image="hero.bannerBackgroundImage"
      :rich-heading-text="hero.richHeadingText"
      @valid-postcode="handleValidPostcode"
    />
    <ContentBlockList :blocks="pageContent.body" />
    <teleport to="head">
      <link rel="canonical" :href="canonicalLink" />
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations, mapGetters } from "vuex"
import HeroBannerHomepage from "@soenergy/frontend-library/src/components/HeroBannerHomepage.vue"
import ContentBlockList from "@soenergy/frontend-library/src/components/ContentBlockList.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"

import story from "soenergy-cms-loader!?path=solar/solar-battery"
import serveUserBasedOnPostcode from "@/helpers/serveUserBasedOnPostcode"

export default defineComponent({
  name: "SolarBattery",
  components: {
    HeroBannerHomepage,
    ContentBlockList,
  },
  mixins: [cmsPreviewMixin({ story })],
  computed: {
    hero() {
      return this.pageContent.hero[0]
    },
    loggedIn() {
      return this.isLoggedIn()
    },
    showButton() {
      return this.loggedIn ? this.hero.button : null
    },
    loginOption() {
      return !this.loggedIn ? this.hero.loginOption : null
    },
    canonicalLink() {
      return process.env.VUE_APP_SOLAR_SITE_URL + this.$route.path
    },
  },
  methods: {
    ...mapMutations({ setPostcode: "SET_POSTCODE", resetState: "RESET_STATE" }),
    ...mapGetters(["isLoggedIn"]),
    handleValidPostcode(postcode) {
      this.resetState()
      this.setPostcode(postcode)
      serveUserBasedOnPostcode(postcode)
    },
  },
})
</script>
