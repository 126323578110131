<template>
  <div class="address-input">
    <Combobox
      v-model="addressModel"
      class="address-input__select"
      :options="options"
      :reduce="option => option.value"
      v-bind="$attrs"
    />
    <p
      v-if="showManualInput && addresses.length > 0"
      class="address-input__text address-input__error-text"
    >
      Please check through the list carefully for your address. If it isn’t
      shown, please enter your address details below.
    </p>
    <template v-if="showManualInput && !noManualInput">
      <div class="address-input__address-lines">
        <InputField
          v-model="firstLineAddressModel"
          v-bind="firstLineInputConfigCombined"
        />
        <InputField
          v-model="secondLineAddressModel"
          v-bind="secondLineInputConfigCombined"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Combobox from "./Combobox"
import InputField from "./InputField"
import { deepClone } from "../helpers/objectHelper"

const firstLineInputConfig = {
  name: "first-line-address",
  label: "First line of address"
}
const secondLineInputConfig = {
  name: "second-line-address",
  label: "Second line of address"
}

export default {
  components: {
    Combobox,
    InputField
  },
  inheritAttrs: false,
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    address: {
      type: [Object, String],
      default: null
    },
    firstLineAddress: {
      type: String,
      default: null
    },
    secondLineAddress: {
      type: String,
      default: null
    },
    firstLineInputConfig: {
      type: Object,
      default: () => firstLineInputConfig
    },
    secondLineInputConfig: {
      type: Object,
      default: () => secondLineInputConfig
    },
    addressMissingValue: {
      type: String,
      default: "address_not_listed"
    },
    returnManualAddressAsObject: {
      type: Boolean,
      default: false
    },
    noManualInput: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ addressLine1: null, addressLine2: null }),
  computed: {
    firstLineInputConfigCombined() {
      return {
        ...firstLineInputConfig,
        ...this.firstLineInputConfig
      }
    },
    secondLineInputConfigCombined() {
      return {
        ...secondLineInputConfig,
        ...this.secondLineInputConfig
      }
    },
    addressEnteredManually() {
      return this.address?.isEnteredManually
        ? this.address
        : {
            isEnteredManually: true,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2
          }
    },
    showManualInput() {
      return (
        this.address === this.addressMissingValue ||
        (this.address && this.address.isEnteredManually)
      )
    },
    options() {
      const options = this.addresses.map(item => {
        return {
          label: item.address,
          value: this.returnManualAddressAsObject ? item.value : item
        }
      })
      options.push({
        label: "My address is not listed",
        value: this.returnManualAddressAsObject
          ? deepClone(this.addressEnteredManually)
          : this.addressMissingValue
      })
      return options
    },
    addressModel: {
      get() {
        return deepClone(this.address)
      },
      set(event) {
        this.$emit(`update:address`, event)
      }
    },
    firstLineAddressModel: {
      get() {
        return this.returnManualAddressAsObject
          ? this.address.addressLine1
          : this.firstLineAddress
      },
      set(addressLine1) {
        if (this.returnManualAddressAsObject) {
          this.$emit(`update:address`, {
            ...this.addressEnteredManually,
            addressLine1
          })
        } else {
          this.$emit(`update:firstLineAddress`, addressLine1)
        }
      }
    },
    secondLineAddressModel: {
      get() {
        return this.returnManualAddressAsObject
          ? this.address.addressLine2
          : this.secondLineAddress
      },
      set(addressLine2) {
        if (this.returnManualAddressAsObject) {
          this.$emit(`update:address`, {
            ...this.addressEnteredManually,
            addressLine2
          })
        } else {
          this.$emit(`update:secondLineAddress`, addressLine2)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-input {
  &__select {
    max-width: $width-4;
  }

  &__text {
    font-size: $font-size-1;
    margin-top: $spacing-4;
    margin-bottom: $spacing-7;
    max-width: $width-4;

    @include md {
      font-size: $size-7;
      margin-top: $spacing-2;
    }
  }

  &__error-text {
    color: $fire-dark;
    margin-top: $spacing-4;
    margin-bottom: $spacing-3;
  }

  &__address-lines {
    margin-bottom: $spacing-6;
  }
}
</style>
