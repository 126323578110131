export const footerNavItems = [
  {
    title: "The Company",
    navTitle: [],
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/about-us`,
        name: "About So Energy",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/tariffs`,
        name: "Our Tariffs",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/support/solutions/articles/7000070564-feed-in-tariff-fit-faqs`,
        name: "Feed In Tariff",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/help/emergency`,
        name: "Emergency",
        component: "NavItem"
      },
      {
        link: "https://careers.so.energy/",
        name: "Careers",
        component: "NavItem"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "Important information",
    navTitle: [],
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/terms`,
        name: "T&Cs",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/privacy`,
        name: "Privacy Policy",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/cookies`,
        name: "Cookie Policy",
        component: "NavItemWebsite"
      },
      {
        text: "Cookie Preferences",
        emitEvent: "reopenCookieModal",
        component: "eventLink"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/modern-slavery`,
        name: "Modern Slavery Statement",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/pay-report`,
        name: "Gender & Ethnicity Pay Report",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/accessibility-statement`,
        name: "Accessibility Statement",
        component: "NavItem"
      },
      {} // this is here so the content splits at 4+4 items
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [],
    navTitle: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  }
]
