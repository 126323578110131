<template>
  <div class="card" :class="{ 'card--two-cols': twoColumnLayout }">
    <div v-if="$slots.heading" class="card__heading">
      <slot name="heading"></slot>
    </div>

    <div v-if="twoColumnLayout" class="card__right-col">
      <slot name="rightColumn"></slot>
    </div>

    <div class="card__content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    twoColumnLayout: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  @include md {
    margin: 0 auto;
    background: $white;
    border: 1px solid $grey-400;
    border-radius: $space-2;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: $space-8;

    &--two-cols &__heading,
    &--two-cols &__content {
      width: 45%;
      float: left;
      padding-right: $space-5;
    }
    &--two-cols &__right-col {
      width: 55%;
      float: right;
    }
    // float clearfix
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }
}
</style>
