<template>
  <div v-if="pageContent" class="warranty-container">
    <h1 class="warranty-title">{{ pageContent.title }}</h1>
    <div class="warranty-content">
      <Dropdown
        v-for="(content, id) in pageContent.body"
        :key="id"
        class="warranty-dropdown"
        :dropdown-content="content"
      />
    </div>
    <teleport to="head">
      <link rel="canonical" :href="canonicalLink" />
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import Dropdown from "@/components/Dropdown.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/warranties-and-manuals"

export default defineComponent({
  name: "WarrantiesAndManuals",
  components: {
    Dropdown,
  },
  mixins: [cmsPreviewMixin({ story })],
  computed: {
    canonicalLink() {
      return process.env.VUE_APP_SOLAR_SITE_URL + this.$route.path
    },
  },
  methods: {},
})
</script>

<style lang="scss" scoped>
.warranty {
  &-container {
    margin: 0 $space-5;
    padding: $space-8 0;
  }

  &-title {
    text-transform: none;
    margin: auto;
    padding: $space-6 0;

    @include md {
      text-align: center;
      padding: $space-10 0;
    }
  }

  &-content {
    margin-bottom: $space-8;
  }

  &-dropdown {
    margin: auto;
    max-width: $width-6;
  }
}
</style>
