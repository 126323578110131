<template>
  <div v-if="pageContent" class="electricity-unit-rate">
    <h1 class="electricity-unit-rate__title h4">
      {{ pageContent.title }}
    </h1>
    <p class="electricity-unit-rate__description">
      {{ pageContent.description }}
    </p>
    <RadioGroup
      v-model="isUnitRateExact"
      class="electricity-unit-rate__unit-rate-type"
    >
      <RadioButton
        :value="true"
        :label="pageContent.exactAmountButtonCopy"
        input-id="is-unit-rate-exact"
      />
      <InputField
        v-if="isUnitRateExact"
        ref="unit-rate-input"
        v-model="exactUnitRate"
        :has-error="isUnitRateOutOfRange"
        :error-message="unitRateErrorMessage"
        label="Amount (p/kWh)"
        name="exact-unit-rate-p-per-kwh"
        type="number"
        required
      />
      <RadioButton
        :value="false"
        :label="pageContent.nationalAverageButtonCopy"
        input-id="is-unit-rate-estimated"
      />
    </RadioGroup>
    <AppButton
      class="electricity-unit-rate__next-button"
      :disabled="!unitRateSelected"
      full-width
      @click="next"
    >
      <span>{{ pageContent.nextStepButtonCopy }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup.vue"
import RadioButton from "@soenergy/frontend-library/src/components/RadioButton/RadioButton.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/electricity-unit-rate"
import { mapGetters, mapMutations } from "vuex"

const MIN_UNIT_RATE = 10
const MAX_UNIT_RATE = 70
const UNIT_RATE_ERROR_MESSAGE = `Please enter a number between ${MIN_UNIT_RATE}p/kwh and ${MAX_UNIT_RATE}p/kwh`

export interface SolarQuoteElectricityUnitRate {
  title: string
  description: string
  exactAmountButtonCopy: string
  nationalAverageButtonCopy: string
  nextStepButtonCopy: string
}

export default defineComponent({
  components: {
    AppButton,
    InputField,
    RadioButton,
    RadioGroup,
  },
  mixins: [cmsPreviewMixin<SolarQuoteElectricityUnitRate>({ story })],
  emits: ["go-forward"],
  computed: {
    ...mapGetters(["getIsUnitRateExact", "getUnitRate"]),
    isUnitRateExact: {
      get(): boolean {
        return this.getIsUnitRateExact
      },
      set(value) {
        this.setIsUnitRateExact(value)
      },
    },
    exactUnitRate: {
      get(): string {
        return this.getUnitRate.value
      },
      set(value) {
        this.setUnitRate({ value })
      },
    },
    isUnitRateOutOfRange() {
      const unitRate = Number(this.exactUnitRate)
      if (this.exactUnitRate) {
        return unitRate < 10 || unitRate > 70
      } else {
        return false
      }
    },
    unitRateErrorMessage() {
      return this.isUnitRateOutOfRange ? UNIT_RATE_ERROR_MESSAGE : null
    },
    unitRateSelected() {
      return (
        this.isUnitRateExact === false ||
        (this.isUnitRateExact &&
          !!this.exactUnitRate &&
          !this.isUnitRateOutOfRange)
      )
    },
  },
  methods: {
    ...mapMutations({
      setIsUnitRateExact: "SET_IS_UNIT_RATE_EXACT",
      setUnitRate: "SET_UNIT_RATE",
    }),
    next() {
      if (!this.exactUnitRate) {
        this.setUnitRate({ value: "National Average" })
      }
      this.$emit("go-forward")
    },
  },
})
</script>

<style lang="scss" scoped>
.electricity-unit-rate {
  &__title {
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-top: $space-6;

    @include md {
      margin-top: $space-10;
    }
  }

  &__unit-rate-type {
    margin-top: $space-6;
  }

  &__next-button {
    margin-top: $space-3;
  }
}
</style>
