<template>
  <section v-if="pageContent" class="customer-check">
    <h1 class="customer-check__title h4">
      {{ pageContent.title }}
    </h1>
    <RadioGroup v-model="isCustomer" :options="options" />

    <AppButton
      :disabled="isCustomer === null"
      class="customer-check__next-button"
      full-width
      @click="$emit('go-forward')"
    >
      <span>{{ pageContent.nextStepButtonCopy }}</span>
      <span class="customer-check__icon icon icon-arrow-right" />
    </AppButton>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue"
import { useStore } from "@/store"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import { RadioGroup } from "@soenergy/frontend-library/src/components/RadioButton"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"

import story from "soenergy-cms-loader!?path=solar/customer-check"
import TrackingService from "@soenergy/frontend-library/src/services/TrackingService"

export interface SolarQuoteCustomerCheck {
  title: string
  nextStepButtonCopy: string
}

export default defineComponent({
  emits: ["go-forward"],
  setup() {
    const store = useStore()
    const isCustomer = computed({
      get() {
        return store.state.quote.isExistingCustomer
      },
      set(value) {
        TrackingService.sendEvent(`Solar:IsExistingCustomer:${!!value}`)
        store.commit("SET_IS_EXISTING_CUSTOMER", value)
      },
    })
    const options = [
      {
        label: "Yes",
        value: true,
        inputId: "customer-check-yes",
      },
      {
        label: "No",
        value: false,
        inputId: "customer-check-no",
      },
    ]
    return {
      isCustomer,
      options,
    }
  },
  mixins: [cmsPreviewMixin<SolarQuoteCustomerCheck>({ story })],
  components: {
    AppButton,
    RadioGroup,
  },
})
</script>

<style scoped lang="scss">
.customer-check {
  &__title {
    text-transform: none;
  }

  &__next-button {
    margin-top: $space-8;

    &__icon {
      margin-left: $space-2;
    }
  }
}
</style>
