<template>
  <div
    :class="[
      'combobox',
      {
        'combobox--selected': isSelected,
        'combobox--disabled': $attrs.disabled,
        'combobox--loading': loading,
        'combobox--error': hasError,
        'combobox--has-focus': hasFocus,
        'combobox--has-chevron': !hasDeselect
      }
    ]"
  >
    <div class="combobox__selector">
      <v-select
        class="size-normal combobox__input"
        :options="options"
        :label="optionLabel"
        :input-id="inputId"
        :components="{ Deselect: hasDeselect && !$attrs.disabled ? CloseIcon : null }"
        transition=""
        v-bind="$attrs"
        placeholder=""
        :value="value"
        v-on="inputListeners"
        @search:focus="hasFocus = true"
        @search:blur="hasFocus = false"
      >
        <template v-if="label" #header>
          <label class="combobox__label" :for="inputId">{{ label }}</label>
        </template>
        <template #option="option">
          <slot v-bind="option">
            <span :title="option[optionLabel]">
              {{ option[optionLabel] }}
            </span>
          </slot>
        </template>
        <template #open-indicator>
          <i v-if="loading" class="loading-spinner-icon"></i>
          <i v-else class="combobox__icon-open icon icon-chevron-down"></i>
        </template>
        <template #no-options>
          {{ noMatchingOptionsLabel }}
        </template>
      </v-select>
      <div
        v-if="errorMessage"
        class="combobox__error-message form-group__invalid-feedback"
        role="alert"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select"

export default {
  components: {
    vSelect
  },
  inheritAttrs: false,
  props: {
    /**
     * An array of strings or objects to be used as dropdown choices.
     * When using an array of objects, the labels to be displayed are stored
     * under the `label` key (eg. [{label: 'This is Foo', value: 'foo'}]). A
     * custom label key can be set with the `optionLabel` prop.
     */
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
      type: String,
      default: "label"
    },
    value: {
      type: [String, Object, Number, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    inputId: {
      type: String,
      required: true
    },
    noMatchingOptionsLabel: {
      type: String,
      default: "No matching options"
    },
    errorMessage: {
      type: String,
      default: ""
    },
    hasError: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasDeselect: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    CloseIcon: {
      render: createElement => createElement("i", { class: "icon icon-close" })
    },
    hasFocus: false
  }),
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit("input", event)
      }
    },
    isSelected() {
      return Boolean(this.value)
    }
  }
}
</script>

<style lang="scss">
// Setting default plugin styling
$vs-colors: (
  lightest: $grey-200,
  light: $grey-200,
  dark: $grey-900,
  darkest: $grey-900
) !default;

$vs-state-disabled-bg: $disabled-01;
$vs-state-disabled-color: $disabled-03;
$vs-state-disabled-controls-color: $disabled-03;

$vs-border-radius: 8px;
$vs-border-width: 1px;
$vs-component-line-height: $space-12;
$vs-dropdown-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
  0px 2px 6px -1px rgba(0, 0, 0, 0.1);
$vs-border-color: $grey-700;
$vs-selected-border-color: $grey-900;
$vs-state-active-bg: $grey-200;
$vs-state-active-color: $grey-900;
$vs-component-placeholder-color: $grey-800;

@import "vue-select/src/scss/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "../stylesheets/components/_form.scss";

.combobox {
  &__label {
    font-size: $font-size-3;
    position: absolute;
    transform: translate(0, $space-5);
    padding-left: $space-4;
    transition: 0.3s ease-in-out;

    display: inline-block;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 44px);
  }

  &__input {
    margin-top: $space-3;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 4px $focus;
    }
  }

  &__selector {
    position: relative;
  }

  &--selected & {
    &__icon-open {
      display: none;
    }
  }

  // override "selected" if needed
  &--has-chevron & {
    &__icon-open {
      display: inline-block;
    }
  }

  &--disabled & {
    &__label {
      color: $grey-700;
    }
  }

  &--error ::v-deep {
    .vs__dropdown-toggle {
      border-color: $danger-02;
      box-shadow: 0px 0px 0px 1px $danger-02;
    }

    .vs__search {
      font-weight: $weight-medium;
    }
  }

  &--loading {
    pointer-events: none;
  }

  .vs--open &__icon-open {
    transform: rotate(180deg);
    display: block;
  }

  .vs--open ::v-deep,
  &--selected ::v-deep {
    .vs__dropdown-toggle .icon {
      color: $grey-900;
    }
  }

  .vs--open ::v-deep {
    .vs__dropdown-toggle {
      border-bottom-color: $grey-500;
    }
  }
}

.combobox--has-focus ::v-deep {
  .vs__dropdown-toggle {
    box-shadow: 0px 0px 0px 4px $focus;
  }

  .vs__dropdown-menu {
    box-shadow: 0 -1px 0 $grey-500, 0 -5px 0 #fff, 0 0 0 4px $focus;
  }
}

// Customising stying of plugin elements
.combobox__input ::v-deep {
  .vs__dropdown-toggle {
    padding: 6px 0px 6px;
  }

  .vs__clear {
    display: flex;
    margin: 0;
  }

  .vs__actions {
    padding: 0px 16px 0px;
  }

  .vs__dropdown-menu {
    top: 100%;
    border-color: $grey-700;
    padding: $space-4 0px;
  }

  .vs__selected-options {
    flex-wrap: nowrap;
    padding: 0px 0px 2px 16px;
    height: 56px;
  }

  .vs__dropdown-option {
    padding: $space-2 $space-7;
    font-size: $font-size-3;
    line-height: 1;
    white-space: normal;

    @include sm {
      white-space: nowrap;
    }

    &--highlight {
      font-weight: $weight-medium;
    }
  }

  .vs__search {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  .vs__search,
  .vs__selected {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: $font-size-3;
  }

  .vs__selected {
    font-weight: $weight-medium;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ul {
    li {
      div {
        display: inline-block !important; // To overwrite this (3rd party's) use of style="display: flex" on this element.
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .icon {
    font-size: $font-size-5;
    font-weight: $weight-bold;
    color: $grey-700;
  }

  .loading-spinner-icon {
    &::before {
      @include loading-spinner;
      font-size: $font-size-4;
      margin-right: $space-2;
    }
  }

  input {
    background-color: rgba(0, 0, 0, 0);
  }
}

.combobox--selected .combobox__input ::v-deep,
.combobox__input.vs--open ::v-deep {
  .vs__selected,
  input {
    position: absolute;
    transform: translate(0, $space-2);
    width: 100%;
    font-weight: $weight-medium;
    font-size: $font-size-3;
  }

  .combobox__label {
    font-size: $font-size-2;
    transform: translate(0, $space-2);
  }

  // input.focus hides placeholder on component's "first use"
  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-moz-placeholder {
    opacity: 0;
  }
  input:focus:-ms-input-placeholder {
    opacity: 0;
  }
  input:focus:-moz-placeholder {
    opacity: 0;
  }
}

.combobox__input.vs--open ::v-deep {
  .vs__selected {
    opacity: 0;
  }
}

.combobox--disabled ::v-deep {
  .vs__selected {
    color: $grey-700;
  }
}
</style>
