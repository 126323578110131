<template>
  <LoadingOverlay :is-active="isLoading" />
  <section class="section register-interest">
    <div class="register-interest__container">
      <h1 v-if="pageContent.title" class="register-interest__title h4">
        {{ pageContent.title }}
      </h1>
      <div
        ref="typeformWrapper"
        class="register-interest__typeform-element"
      ></div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapGetters } from "vuex"
import TypeformService from "@soenergy/frontend-library/src/services/TypeformService"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"

import story from "soenergy-cms-loader!?path=solar/register-interest&force_prerender=true"

interface SolarRegisterInterestPage {
  title: string
  typeformIdForNotCoveredAreas: string
  typeformIdForCoveredAreas: string
}

export default defineComponent({
  name: "RegisterInterest",
  components: {
    LoadingOverlay,
  },
  mixins: [cmsPreviewMixin<SolarRegisterInterestPage>({ story })],
  data: () => ({ isLoading: true }),
  computed: {
    ...mapGetters(["postcode"]),
  },
  mounted() {
    const isCovered = this.$route.query.covered
    const postcode = this.$store.state.quote.postcode

    const inputDataProvided = !!isCovered && !!postcode
    if (!inputDataProvided) {
      this.$router.push("/")
      return
    }

    this.loadTypeformForPostcode(isCovered === "true", postcode)
  },
  methods: {
    loadTypeformForPostcode(isPostcodeSupported, postcode) {
      const typeformId = isPostcodeSupported
        ? this.pageContent.typeformIdForCoveredAreas
        : this.pageContent.typeformIdForNotCoveredAreas

      TypeformService.openWidget(typeformId, {
        container: this.$refs.typeformWrapper,
        inlineOnMobile: true,
        onReady: async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          this.isLoading = false
        },
        onSubmit: () => {
          this.$router.push({
            path: "/thank-you",
            query: { covered: isPostcodeSupported },
          })
        },
        hidden: {
          postcode,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.register-interest {
  background-color: $grey-50;
  padding-top: $space-4;

  @include md {
    padding-top: $space-8;
  }

  &__container {
    height: 100%;
    padding-top: 0;
    padding-bottom: $space-6;

    @include md {
      min-height: 80vh;
      padding: 0 0 $space-6 0;
      margin: 0 auto;
      width: $width-7;
    }
  }

  &__title {
    text-transform: none;
    margin-top: $space-10;
  }

  &__typeform-element {
    height: 500px;
    margin-top: $space-10;

    @include md {
      margin-top: $space-13;
    }
  }
}
</style>
