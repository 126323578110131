import type { VueCookies } from "vue-cookies"

export const UTM_COOKIE_EXPIRATION_TIME = "6m" // 6 months
export const UTM_COOKIE_NAME = "visitor_utm"

export type UtmParams = {
  source: string
  medium: string
  content: string
  url: string
  id: string | null
  name: string | null
  term: string | null
  gclid: string | null
}

export default {
  init(cookieManager: VueCookies) {
    this.cookieManager = cookieManager

    if (this.hasValidUtmParams(window.location.href)) {
      const utmParams = this.extractUtmParamsFromUrl(window.location)
      this.setUtmCookie(utmParams)
      return
    }

    // Check if redirected url contains utm params
    if (this.hasValidUtmParams(document.referrer)) {
      const utmParams = this.extractUtmParamsFromUrl(document.referrer)
      this.setUtmCookie(utmParams)
    }
  },

  hasValidUtmParams(urlString: string) {
    try {
      const url = new URL(urlString)
      if (url.host !== window.location.host) return false
      return url.searchParams.has("utm_source")
    } catch (e) {
      return false
    }
  },

  setUtmCookie(utmParams: UtmParams) {
    this.cookieManager.set(
      UTM_COOKIE_NAME,
      utmParams,
      UTM_COOKIE_EXPIRATION_TIME
    )
  },

  extractUtmParamsFromUrl(urlString: string): UtmParams {
    const { searchParams, origin } = new URL(urlString)
    return {
      source: searchParams.get("utm_source"),
      id: searchParams.get("utm_id"),
      medium: searchParams.get("utm_medium"),
      name: searchParams.get("utm_name"),
      term: searchParams.get("utm_term"),
      content: searchParams.get("utm_content"),
      gclid: searchParams.get("gclid"),
      url: origin,
    }
  },
}
