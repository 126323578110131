<template>
  <section class="step-wizard">
    <ProgressBar
      v-if="!hideProgressBar"
      class="step-wizard__progress-indicator"
      :steps="steps"
      :current-step-number="currentStepNumber"
      :aria-label="progressBarAriaLabel"
    />
    <div class="step-wizard__wrapper">
      <BackLink
        v-if="!hideBackButton"
        class="step-wizard__back-link"
        disable-router-back
        @go-back-to="goToPreviousStep"
      />
      <h1 v-if="!hideTitle && isStepGiven" class="step-wizard__title h4">
        {{ steps[currentStepNumber].title }}
      </h1>
      <router-view
        @go-back="goToPreviousStep"
        @go-forward="goToNextStep"
        @go-specific="goToSpecificStep"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import type { PropType } from "vue"

import ProgressBar from "@soenergy/frontend-library/src/components/ProgressBar.vue"
import BackLink from "@soenergy/frontend-library/src/components/BackLink.vue"

interface Step {
  routeName: string
  title?: string
  skip?: boolean
}

export default defineComponent({
  expose: [
    "goToPreviousStep",
    "goToNextStep",
    "goToSpecificStep",
    "change",
    "completed",
  ],
  components: {
    ProgressBar,
    BackLink,
  },
  model: {
    prop: "currentStepNumber",
    event: "change",
  },
  props: {
    steps: {
      type: Array as PropType<Array<Step>>,
      required: true,
    },
    currentStepNumber: {
      type: Number,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    hideProgressBar: {
      type: Boolean,
      default: false,
    },
    entryRouteName: {
      type: String,
      default: "",
    },
  },
  emits: ["change", "returned", "completed"],
  data: () => ({ initialRouteHandled: false }),
  computed: {
    isStepGiven() {
      return !!this.currentStepNumber || this.currentStepNumber === 0
    },
    currentStep() {
      return this.steps[this.currentStepNumber]
    },
    progressBarAriaLabel() {
      const stepNumber = this.currentStepNumber + 1
      return `Step ${stepNumber} out of ${this.steps.length}`
    },
  },
  watch: {
    currentStepNumber: {
      handler(number) {
        if (
          this.isStepGiven &&
          this.steps[number].routeName !== this.$route.name
        ) {
          this.goToStepNumber(number)
        }
      },
      immediate: true,
    },
    $route: {
      handler(newRoute) {
        if (!this.initialRouteHandled && this.isStepGiven) return
        this.initialRouteHandled = true

        const stepMatchingRoute = this.getStepIndexByRouteName(newRoute.name)
        if (stepMatchingRoute > -1) {
          this.$emit("change", stepMatchingRoute)
        }
      },
      immediate: true,
    },
  },
  methods: {
    goToPreviousStep() {
      const stepNumber = this.findNextStep(this.currentStepNumber, false)
      if (stepNumber) {
        this.goToStepNumber(stepNumber)
      } else {
        this.$emit("returned")
      }
    },
    goToNextStep() {
      const stepNumber = this.findNextStep(this.currentStepNumber, true)
      if (stepNumber) {
        this.goToStepNumber(stepNumber)
      } else {
        this.$emit("completed")
      }
    },
    getStepIndexByRouteName(routeName) {
      return this.steps.findIndex((step) => step.routeName === routeName)
    },
    goToSpecificStep(routeName) {
      const stepIndexMatchingRoute = this.getStepIndexByRouteName(routeName)
      this.goToStepNumber(stepIndexMatchingRoute)
    },
    findNextStep(currentNumber, goNext) {
      const increment = goNext ? 1 : -1
      do {
        currentNumber += increment
        if (currentNumber < 0 || currentNumber > this.steps.length - 1) {
          return null
        }
      } while (this.steps[currentNumber].skip)

      return currentNumber
    },
    goToStepNumber(number) {
      this.$router.push({
        name: this.steps[number].routeName,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.step-wizard {
  &__wrapper {
    padding-top: $spacing-7;

    @include md {
      padding-top: $space-10;
    }
  }
  &__back-link {
    margin-top: 0;
    margin-bottom: $space-9;

    @include md {
      margin-bottom: $space-10;
    }
  }

  &__title {
    margin-bottom: $space-8;

    @include md {
      margin-bottom: $space-10;
    }
  }
}
</style>
