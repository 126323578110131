import loadScriptFromUrl from "../helpers/loadScriptFromUrl"
import styleVars from "../stylesheets/_export.module.scss"
import newBrandingStyleVars from "../stylesheets/_export_new_branding.module.scss"

export default {
  async getCalendlyClient() {
    await loadScriptFromUrl(
      "https://assets.calendly.com/assets/external/widget.js"
    )
  },
  async initWidget() {
    await this.getCalendlyClient()
    Calendly.initBadgeWidget({
      url: `https://calendly.com/solar-so/book-a-call?primary_color=${sanitizeColor(
        getPrimaryColor()
      )}`,
      text: "Book a call",
      color: buttonPrimaryColor(),
      textColor: styleVars["primary-black"],
      resize: true,
      branding: undefined
    })
  },
  async openPopup() {
    Calendly.initPopupWidget({
      url: `https://calendly.com/solar-so/book-a-call?&text_color=${sanitizeColor(
        styleVars["primary-black"]
      )}&primary_color=${sanitizeColor(getPrimaryColor())}&resize=true`
    })
  }
}

function getPrimaryColor() {
  return process.env.VUE_APP_NEW_BRANDING === "true"
    ? newBrandingStyleVars["primary-black"]
    : styleVars["green-green_400"]
}

function buttonPrimaryColor() {
  return process.env.VUE_APP_NEW_BRANDING === "true"
    ? newBrandingStyleVars["green-green_400"]
    : styleVars["green-green_400"]
}

export function sanitizeColor(color) {
  if (color?.startsWith("#")) {
    return color.slice(1)
  }
  return color
}
