<template>
  <section v-if="pageContent" class="section register">
    <div class="register__container">
      <BackLink class="register__back-link" :link="getLink()" />
      <Article
        class="register__copy"
        v-bind="
          isFlatRoof
            ? cmsBlockToProps(pageContent.flatRoofCopy)
            : cmsBlockToProps(pageContent.copy)
        "
        :variable-set="contentVariables"
      />
      <Combobox
        ref="title-combobox"
        v-model="title"
        input-id="select-title"
        :options="['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr']"
        label="Title"
        class="mb-4 register__title form-group__input-group"
      />
      <InputField
        id="first-name"
        ref="first-name-input"
        v-model="firstName"
        name="firstName"
        label="First name"
        :error-message="
          v$.firstName.$error
            ? pageContent.firstNameInputFieldErrorMessage
            : null
        "
        :has-error="v$.firstName.$error"
        @blur="v$.firstName.$touch()"
      />
      <InputField
        id="last-name"
        ref="last-name-input"
        v-model="lastName"
        name="lastName"
        label="Last name"
        :error-message="
          v$.lastName.$error ? pageContent.lastNameInputFieldErrorMessage : null
        "
        :has-error="v$.lastName.$error"
        @blur="v$.lastName.$touch()"
      />
      <InputField
        id="email"
        ref="email-input"
        v-model="email"
        name="email"
        label="Email"
        :error-message="
          v$.email.$error ? pageContent.emailInputFieldErrorMessage : null
        "
        :has-error="v$.email.$error"
        @blur="v$.email.$touch()"
      />
      <InputField
        id="phone"
        ref="phone-input"
        v-model="phone"
        name="phone"
        label="Phone"
        :error-message="
          v$.phone.$error ? pageContent.phoneInputFieldErrorMessage : null
        "
        :has-error="v$.phone.$error"
        @blur="v$.phone.$touch()"
      />
      <Checkbox
        v-model="dataConsent"
        class="register__checkbox"
        input-id="data-consent"
        is-small
      >
        <Article
          :text-content="pageContent.dataConsentCheckboxCopy"
          no-margin
        />
      </Checkbox>
      <div class="contact-details__recaptcha-wrapper">
        <Recaptcha
          ref="recaptcha"
          :sitekey="siteKey"
          @success="recaptchaSuccess"
        ></Recaptcha>
      </div>
      <AppButton
        class="register__next-button"
        full-width
        size="large"
        :disabled="!dataConsent || !recaptchaSucceeded"
        :loading="loading"
        @click="submit"
      >
        {{ pageContent.submitBtnCopy }}
      </AppButton>
      <FeedbackDialog
        v-if="showSubmissionError"
        class="register__feedback-dialog"
        :message="pageContent.genericSubmissionError"
        variant="negative"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "@/store"
import useVuelidate from "@vuelidate/core"
import {
  required,
  email as emailValidator,
  numeric,
  minLength,
} from "@vuelidate/validators"
import Recaptcha from "@soenergy/frontend-library/src/components/Recaptcha.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox.vue"
import BackLink from "@soenergy/frontend-library/src/components/BackLink.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import Combobox from "@soenergy/frontend-library/src/components/Combobox.vue"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import { POSTCODE } from "../variable_keys/Quote"
import story from "soenergy-cms-loader!?path=solar/register-for-updates"
import { Page } from "@/router/types"
import { VueCookies } from "vue-cookies"
import { inject } from "vue"
import { UTM_COOKIE_NAME } from "@/services/UtmService"

export interface SolarRegisterForUpdates {
  copy: object
  flatRoofCopy: object
  submitBtnCopy: string
  genericSubmissionError: string
  emailInputFieldErrorMessage: string
  firstNameInputFieldErrorMessage: string
  lastNameInputFieldErrorMessage: string
  phoneInputFieldErrorMessage: string
  dataConsentCheckboxCopy: object
}

export default defineComponent({
  components: {
    AppButton,
    Article,
    BackLink,
    Checkbox,
    FeedbackDialog,
    InputField,
    Recaptcha,
    Combobox,
  },
  mixins: [cmsPreviewMixin<SolarRegisterForUpdates>({ story })],
  setup() {
    const router = useRouter()
    const store = useStore()
    const $cookies = inject<VueCookies>("$cookies")
    const firstName = computed({
      get() {
        return store.state.quote.firstName
      },
      set(value) {
        store.commit("SET_FIRST_NAME", value)
      },
    })
    const lastName = computed({
      get() {
        return store.state.quote.lastName
      },
      set(value) {
        store.commit("SET_LAST_NAME", value)
      },
    })
    const title = computed({
      get() {
        return store.state.quote.title
      },
      set(value) {
        store.commit("SET_TITLE", value)
      },
    })
    const email = computed({
      get() {
        return store.state.quote.email
      },
      set(value) {
        store.commit("SET_EMAIL", value)
      },
    })
    const phone = computed({
      get() {
        return store.state.quote.phone
      },
      set(value) {
        store.commit("SET_PHONE", value)
      },
    })
    const dataConsent = computed({
      get() {
        return store.state.quote.dataConsent
      },
      set(value) {
        store.commit("SET_DATA_CONSENT", value)
      },
    })
    const rules = computed(() => {
      return {
        firstName: { required },
        lastName: { required },
        email: { required, emailValidator },
        phone: { required, numeric, minLength: minLength(9) },
        dataConsent: { required },
      }
    })
    const isFlatRoof = ref(store.state.quote.isFlatRoof)
    const contentVariables = computed(() => {
      return {
        [POSTCODE]: store.state.quote.postcode!.toUpperCase(),
      }
    })
    const v$ = useVuelidate(rules, {
      firstName,
      lastName,
      email,
      phone,
      dataConsent,
    })

    const loading = ref(false)
    const showSubmissionError = ref(false)
    const submit = async () => {
      if (v$.value.$invalid) {
        v$.value.$touch()
      } else {
        loading.value = true
        showSubmissionError.value = false
        try {
          const utmCookie = $cookies?.get(UTM_COOKIE_NAME)
          await store.dispatch("submitProspectDetails", utmCookie)
          router.push({
            name: Page.ThankYou,
          })
          const backupEmail = email.value
          const backupIsFlatRoof = isFlatRoof.value
          $cookies?.remove(UTM_COOKIE_NAME)

          store.commit("RESET_STATE")

          store.commit("SET_EMAIL", backupEmail)
          store.commit("SET_FLAT_ROOF", backupIsFlatRoof)
        } catch (err) {
          ErrorReporter.report(err, {
            context: "solar submitProspectDetails failed",
          })
          showSubmissionError.value = true
        }
        loading.value = false
      }
    }

    const siteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_KEY
    })

    let recaptchaSucceeded = ref(false)
    let recaptchaKey = ref(null)

    function recaptchaSuccess(response) {
      recaptchaSucceeded.value = true
      store.commit("SET_G_CAPTCHA_RESPONSE", response)
    }

    function getLink() {
      return store.state.quote.isFlatRoof ? "/extra-info" : undefined
    }

    return {
      firstName,
      lastName,
      title,
      email,
      phone,
      dataConsent,
      v$,
      loading,
      showSubmissionError,
      contentVariables,
      siteKey,
      recaptchaSucceeded,
      recaptchaKey,
      recaptchaSuccess,
      submit,
      isFlatRoof,
      getLink,
    }
  },
})
</script>

<style lang="scss" scoped>
.register {
  background-color: $grey-50;
  padding-top: $space-4;

  &__container {
    height: 100%;
    padding-top: 0;
    padding-bottom: $space-6;

    @include md {
      min-height: 80vh;
      padding: 0 0 $space-6 0;
      margin: 0 auto;
      width: $width-4;
    }
  }

  &__back-link {
    margin-bottom: $space-9;

    @include md {
      margin-bottom: $space-10;
    }
  }

  &__copy {
    font-weight: $weight-medium;
    text-transform: unset;
    font-size: $font-size-5;
    line-height: 1.15;
    margin-bottom: $space-9;
  }

  &__checkbox {
    margin-top: $space-6;
    margin-bottom: $space-6;
  }

  &__title {
    background-color: $white;
  }

  &__next-button {
    margin-top: $space-8;
  }

  &__feedback-dialog {
    margin-top: $space-4;
  }
  &__recaptcha-wrapper {
    margin-top: $space-6;
  }
}
</style>
