import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import ContactDetails from "../views/quote/ContactDetails.vue"
import Quote from "../views/quote/Quote.vue"
import WhenGetSolar from "../views/quote/WhenGetSolar.vue"
import WhenAtHome from "../views/quote/WhenAtHome.vue"
import YourCurrentSupplier from "../views/quote/YourCurrentSupplier.vue"
import QuoteSolarPackages from "../views/quote/QuoteSolarPackages.vue"
import QuoteSolarProductType from "@/views/quote/QuoteSolarProductType.vue"
import ConservationArea from "../views/quote/ConservationArea.vue"
import ThankYou from "../views/ThankYou.vue"
import RegisterForUpdates from "../views/RegisterForUpdates.vue"
import RegisterInterest from "../views/RegisterInterest.vue"

import ErrorLayout from "@soenergy/frontend-library/src/components/ErrorLayout.vue"
import CustomerCheck from "../views/quote/CustomerCheck.vue"
import FlatRoofCheck from "@/views/quote/FlatRoofCheck.vue"
import FindYourRoof from "../views/quote/FindYourRoof.vue"
import ExtraInfo from "../views/quote/ExtraInfo.vue"
import QuoteElectricityUsage from "@/views/quote/QuoteElectricityUsage.vue"
import QuoteElectricityUnitRate from "@/views/quote/QuoteElectricityUnitRate.vue"
import QuoteElectricityUsageEstimate from "@/views/quote/QuoteElectricityUsageEstimate.vue"
import QuoteSelectAddress from "@/views/quote/QuoteSelectAddress.vue"
import QuoteEnterPostcode from "@/views/quote/QuoteEnterPostcode.vue"
import QuoteCustomerLogin from "@/views/quote/QuoteCustomerLogin.vue"
import QuoteCustomerDetails from "@/views/quote/QuoteCustomerDetails.vue"
import QuoteNewPostcode from "@/views/quote/QuoteNewPostcode.vue"
import QuoteNewAddress from "@/views/quote/QuoteNewAddress.vue"
import WarrantiesAndManuals from "@/views/WarrantiesAndManuals.vue"
import Faq from "../views/Faq.vue"

import SolarPanels from "@/views/SolarPanels.vue"
import SolarBattery from "@/views/SolarBattery.vue"

import requirePostcodeGuard from "./navigation-guards/require-postcode-guard"
import requireAuthenticateGuard from "./navigation-guards/authenticate-guard"
import getAQuoteGuard from "@/router/navigation-guards/get-a-quote-guard"
import { Page } from "./types"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { getAQuoteCheck: process.env.VUE_APP_NEW_BRANDING === "true" },
  },
  {
    path: "/thank-you",
    name: Page.ThankYou,
    component: ThankYou,
    props: true,
  },
  {
    path: "/register-interest",
    name: Page.RegisterInterest,
    component: RegisterInterest,
  },
  {
    path: "/register-for-updates",
    name: Page.RegisterForUpdates,
    component: RegisterForUpdates,
  },
  {
    path: "/warranties-and-manuals",
    name: Page.WarrantiesAndManuals,
    component: WarrantiesAndManuals,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/solar-panels",
    name: Page.SolarPanels,
    component: SolarPanels,
  },
  {
    path: "/solar-battery",
    name: Page.SolarBattery,
    component: SolarBattery,
  },
  {
    path: "/",
    component: Quote,
    children: [
      {
        path: "customer-check",
        name: Page.CustomerCheck,
        component: CustomerCheck,
        meta: {
          requiresAnon: true,
        },
      },
      {
        path: "login",
        name: Page.QuoteCustomerLogin,
        component: QuoteCustomerLogin,
        meta: {
          requiresAnon: true,
          requirePostcode: false,
        },
      },
      {
        path: "customer-details",
        name: Page.QuoteCustomerDetails,
        component: QuoteCustomerDetails,
        meta: {
          requiresAuth: true,
          requirePostcode: false,
        },
      },
      {
        path: "new-postcode",
        name: Page.QuoteNewPostcode,
        component: QuoteNewPostcode,
        meta: {
          requirePostcode: false,
          requiresAuth: true,
        },
      },
      {
        path: "new-address",
        name: Page.QuoteNewAddress,
        component: QuoteNewAddress,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "find-your-roof",
        name: Page.FindYourRoof,
        component: FindYourRoof,
      },
      {
        path: "extra-info",
        name: Page.ExtraInfo,
        component: ExtraInfo,
      },
      {
        path: "flat-roof-check",
        name: Page.FlatRoofCheck,
        component: FlatRoofCheck,
      },
      {
        path: "select-address",
        name: Page.SelectAddress,
        component: QuoteSelectAddress,
      },
      {
        path: "enter-postcode",
        name: Page.EnterPostcode,
        component: QuoteEnterPostcode,
        meta: {
          requirePostcode: false,
        },
      },
      {
        path: "electricity-usage",
        name: Page.QuoteElectricityUsage,
        component: QuoteElectricityUsage,
      },
      {
        path: "usage-estimate",
        name: Page.QuoteElectricityUsageEstimate,
        component: QuoteElectricityUsageEstimate,
      },
      {
        path: "electricity-unit-rate",
        name: Page.QuoteElectricityUnitRate,
        component: QuoteElectricityUnitRate,
      },
      {
        path: "your-current-supplier",
        name: Page.YourCurrentSupplier,
        component: YourCurrentSupplier,
      },
      {
        path: "conservation-area",
        name: Page.ConservationArea,
        component: ConservationArea,
      },
      {
        path: "solar-packages",
        name: Page.QuoteSolarPackages,
        component: QuoteSolarPackages,
      },
      {
        path: "product-type",
        name: Page.QuoteSolarProductType,
        component: QuoteSolarProductType,
      },
      {
        path: "when-at-home",
        name: Page.WhenAtHome,
        component: WhenAtHome,
      },
      {
        path: "when-get-solar",
        name: Page.WhenGetSolar,
        component: WhenGetSolar,
      },
      {
        path: "contact-details",
        name: Page.ContactDetails,
        component: ContactDetails,
      },
    ],
    meta: {
      requirePostcode: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: ErrorLayout,
    props: {
      variant: "notFoundError",
    },
  },
] as Array<RouteRecordRaw>

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0 }),
})

router.beforeEach(requirePostcodeGuard)
router.beforeEach(requireAuthenticateGuard)
router.beforeEach(getAQuoteGuard)
export default router
