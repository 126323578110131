<template>
  <div class="wrapper">
    <div v-if="pageContent" class="solar-packages">
      <div class="solar-packages__header">
        <h2 class="h4 solar-packages__title">{{ pageContent.title }}</h2>
        <p class="solar-packages__description">{{ pageContent.description }}</p>
      </div>
      <Carousel
        :cards="pageContent.packages"
        :copy="{}"
        no-padding
        buttons-mobile
        margins="small"
        class="solar-packages__carousel"
        @get-quote="next($event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations, mapState } from "vuex"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import Carousel from "@soenergy/frontend-library/src/components/Carousel.vue"
import story from "soenergy-cms-loader!?path=solar/solar-packages"
import { SolarPackages } from "../types/SolarPackages"
import { RootState } from "@/store/untested/types"

export default defineComponent({
  components: {
    Carousel,
  },
  mixins: [cmsPreviewMixin<SolarPackages>({ story })],
  emits: ["go-forward"],
  computed: {
    ...mapState({
      selectedPackage: (state: RootState) => state.quote.solarPackage,
    }),
  },
  watch: {
    selectedPackage: {
      handler() {
        this.$emit("go-forward")
      },
    },
  },
  methods: {
    ...mapMutations({
      setSolarPackage: "SET_SOLAR_PACKAGE",
    }),
    next(selectedPackageDetails) {
      this.setSolarPackage(selectedPackageDetails)
    },
  },
})
</script>

<style lang="scss" scoped>
.solar-packages {
  &__header {
    max-width: $width-7;
  }
  &__description {
    font-size: $font-size-4;
    margin: $space-4 0 $space-2;
  }
  &__article-wrapper {
    max-width: 550px;
  }
  &__carousel {
    padding: 0;
    margin: 0;
  }
}
</style>
