<template>
  <section
    class="carousel-container"
    :style="containerStyle"
    :aria-label="descriptiveLabel"
    aria-roledescription="carousel"
    :class="{
      'carousel-container--bg': backgroundImage,
      'carousel-container--left-title': leftTitle
    }"
    :id="sectionId"
  >
    <div
      class="container carousel-container__inner"
      @keyup.left.exact="moveLeft"
      @keyup.right.exact="moveRight"
    >
      <div
        v-if="heading || imageObject || copy"
        class="carousel-header columns"
      >
        <div class="column">
          <h5 v-if="smallHeading" class="carousel-header__small-heading">
            {{ smallHeading }}
          </h5>
          <h2 class="carousel-header__heading">
            <img
              v-if="
                imageObject && imageObject.image && imageObject.image.filename
              "
              :alt="imageObject.image.alt"
              :src="imageObject.image.filename"
              :width="imageObject.width"
              :height="imageObject.height"
              class="carousel-header__logo"
            />
            {{ heading }}
          </h2>
          <AppButton
            v-if="buttonProps && leftTitle"
            v-track="buttonProps.tracking"
            class="carousel-bottom__button"
            v-bind="buttonProps"
          >
            {{ buttonProps.label }}
          </AppButton>
        </div>
        <div class="carousel-header__description column">
          <Article
            v-if="copy"
            :text-content="copy"
            no-margin
            class="size-normal"
          />
        </div>
      </div>
      <div ref="carousel" class="carousel" :class="carouselClasses">
        <div class="carousel__wrapper">
          <ContentBlockList
            ref="slides"
            class="carousel__slides"
            tag="ul"
            wrapper-element-tag="li"
            :wrapper-element-attrs="slideElementAttributes(carouselClassNames)"
            :blocks="cards"
            v-on="$listeners"
          />
        </div>
      </div>
      <div class="carousel-bottom" :class="carouselBottomClasses">
        <AppButton
          v-if="buttonProps && !leftTitle"
          v-track="buttonProps.tracking"
          class="carousel-bottom__button"
          v-bind="buttonProps"
        >
          {{ buttonProps.label }}
        </AppButton>
        <div>
          <button
            v-track="leftArrowButtonTracking"
            class="carousel-bottom__arrow"
            :class="nextPrevButtonClasses"
            aria-label="Previous"
            @click="moveLeft"
          >
            <img src="../assets/images/icon-arrow-left.svg" alt="Left arrow" />
          </button>
          <button
            v-track="rightArrowButtonTracking"
            class="carousel-bottom__arrow carousel-bottom__arrow--right"
            :class="nextPrevButtonClasses"
            aria-label="Next"
            @click="moveRight"
          >
            <img src="../assets/images/icon-arrow-left.svg" alt="Right arrow" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Article from "./Article"
import AppButton from "./AppButton"
import ContentBlockList from "./ContentBlockList"
import stubDirectives from "../mixins/stubDirectives"
import carousel from "../mixins/carousel"

export default {
  components: {
    Article,
    AppButton,
    ContentBlockList
  },
  mixins: [stubDirectives, carousel],
  inheritAttrs: false,
  props: {
    heading: {
      type: String,
      default: null
    },
    copy: {
      type: Object,
      required: true
    },
    image: {
      type: Object,
      default: null
    },
    sideImage: {
      type: Array,
      default: null
    },
    button: {
      type: Array,
      default: null
    },
    cards: {
      type: Array,
      required: true
    },
    backgroundColor: {
      type: String,
      default: null
    },
    fontColor: {
      type: String,
      default: null
    },
    descriptiveLabel: {
      type: String,
      default: null
    },
    buttonsMobile: {
      type: Boolean,
      default: false
    },
    margins: {
      type: String,
      default: null,
      validator: val => ["small", "large"].includes(val)
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    leftArrowButtonTracking: {
      type: Array,
      default: null
    },
    rightArrowButtonTracking: {
      type: Array,
      default: null
    },
    smallHeading: {
      type: String,
      default: null
    },
    backgroundImage: {
      type: Object,
      default: null
    },
    leftTitle: {
      type: Boolean,
      default: false
    },
    rewind: {
      type: Boolean,
      default: false
    },
    sectionId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      carouselClassNames: {
        slide: "carousel__slide",
        slides: "carousel__slides",
        wrapper: "carousel__wrapper"
      }
    }
  },
  computed: {
    imageObject() {
      if (this.sideImage && this.sideImage.length) {
        return this.sideImage[0]
      }
      if (this.image) {
        return { image: this.image }
      }
      return null
    },
    buttonProps() {
      const buttonBlock = this.button && this.button[0]
      if (buttonBlock) {
        const { _uid, component, ...props } = buttonBlock
        return props
      }
      return null
    },
    nextPrevButtonClasses() {
      return {
        "carousel-bottom__arrow--mobile-buttons": this.buttonsMobile
      }
    },
    carouselClasses() {
      return {
        "carousel__margin-small": this.margins === "small",
        "no-padding": this.noPadding
      }
    },
    carouselBottomClasses() {
      return {
        "carousel-bottom__margin-small": this.margins === "small",
        "carousel-bottom__buttons-right": !this.buttonProps,
        "no-padding": this.noPadding
      }
    },
    containerStyle() {
      const style = {}

      if (this.fontColor) {
        style.color = this.fontColor
      }
      if (this.backgroundColor) {
        style.backgroundColor = this.backgroundColor
      }
      if (this.backgroundImage) {
        style.backgroundImage = `url(${this.backgroundImage.filename})`
      }
      return style
    }
  },
  mounted() {
    this.whenReadyInitiateCarousel(this.carouselClassNames)
  }
}
</script>

<style lang="scss">
.carousel-container {
  overflow: hidden;
  padding: $spacing-7 0;

  @include md {
    padding: $spacing-8 0;
  }

  &--bg {
    background-position: top -4rem right -4.5rem;
    background-repeat: no-repeat;
    padding-top: $space-15;
    background-size: 80%;
    @include sm {
      background-position: left;
      background-size: contain;
    }
  }

  &--left-title {
    @include lg {
      .carousel-container__inner {
        display: flex;
        flex-wrap: wrap;
      }
      .carousel-header {
        width: 350px;
        margin-top: $space-13;

        &__heading {
          font-size: $font-size-7;
        }
      }
      .carousel {
        width: calc(100% - 350px);
      }
      .carousel-bottom {
        width: 100%;
        justify-content: end;
      }
    }
  }
}

.carousel {
  margin-top: $spacing-4;

  @include md {
    margin-top: 3rem;
  }

  &__margin-small {
    @include md {
      margin-top: 0;
    }
  }

  &__wrapper {
    position: relative;
    white-space: nowrap;

    @include md {
      clip-path: inset(0 -100vw 0 0);
    }
  }

  &__slides {
    display: inline-block;
    margin-left: -$space-1;
  }

  &__slide {
    display: inline-block;

    img,
    a,
    button {
      -webkit-user-drag: none; // Dragging clickable elements conflicts with the carousel plugin
    }

    &:not(:first-child) {
      margin-left: $spacing-4;
    }

    &:last-child {
      width: 100vw;

      @include lg {
        width: unset;
      }
    }
  }
}

.carousel-header {
  &__logo {
    height: 42px;
  }

  &__description {
    display: none;

    @include md {
      display: block;
    }
  }
}

.carousel-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-6;

  &__buttons-right {
    justify-content: right;
  }

  @include md {
    margin-top: $spacing-8;
  }

  &__margin-small {
    @include md {
      margin-top: $spacing-6;
    }
  }
  &__button {
    justify-self: left;
  }

  &__arrow {
    display: none;
    align-items: center;
    justify-content: center;
    padding-right: $spacing-2;
    background: transparent;
    width: 56px;
    height: 56px;
    border: none;
    cursor: pointer;

    @include md {
      display: inline-flex;
    }

    &:focus {
      outline: 0;
      &:not(:active) {
        box-shadow: 0;
      }
    }

    &:active {
      background-color: $day-dark;
    }

    &--mobile-buttons {
      display: inline-flex;
    }

    &--right {
      transform: rotate(180deg);
      margin-left: $spacing-1;
    }
  }
}

.carousel-header,
.carousel-bottom,
.carousel {
  padding: 0 $section-horizontal-padding;

  @include sm {
    padding: 0 $section-horizontal-padding-tablet;
  }
  @include lg {
    padding: 0;
  }
}

.no-padding {
  padding: 0;

  @include sm {
    padding: 0;
  }
  @include lg {
    padding: 0;
  }
}
</style>
