import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31a1cc30"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "solar-product-types"
}
const _hoisted_2 = { class: "solar-product-types__header" }
const _hoisted_3 = { class: "h4 solar-product-types__title" }
const _hoisted_4 = { class: "solar-product-types__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, " Choose a " + _toDisplayString(_ctx.solarPackageTitle), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.pageContent.description), 1)
        ]),
        _createVNode(_component_Carousel, {
          cards: _ctx.filteredProductTypes,
          copy: {},
          "no-padding": "",
          "buttons-mobile": "",
          margins: "small",
          class: "solar-product-types__carousel",
          onGetQuote: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next($event)))
        }, null, 8, ["cards"])
      ]))
    : _createCommentVNode("", true)
}