<template>
  <li v-editable="block" :class="listItemClass">
    <div class="ordered-list-item__title-wrapper">
      <span v-if="listItemImage" class="mr-2">
        <img
          class="ordered-list-item__icon"
          :alt="listItemImage.alt"
          :src="listItemImage.filename"
        />
      </span>
      <span v-else :class="numberClass">{{ listIndex }}</span>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="ordered-list-item__title" v-html="generatedHtml"></span>
    </div>
    <ol
      v-if="block.subItems && block.subItems.length"
      class="ordered-list-item__sublist"
    >
      <OrderedListItem
        v-for="(item, index) in block.subItems"
        :key="index"
        :block="item"
        :list-index="listIndex + (index + 1) + '.'"
        :secondary-level="isSecondaryLevel"
      />
    </ol>
  </li>
</template>

<script>
import OrderedListItem from "./OrderedListItem.vue"
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.cjs"

export default {
  name: "OrderedListItem",
  components: {
    OrderedListItem
  },
  props: {
    block: {
      required: true,
      type: Object
    },
    listIndex: {
      required: true,
      type: String
    },
    secondaryLevel: {
      type: Boolean,
      default: false
    },
    listItemImage: {
      type: Object,
      default: null
    }
  },
  computed: {
    isSecondaryLevel() {
      return (
        this.subItems &&
        ((this.listIndex || "").match(/\./g) || []).length === 2
      )
    },
    isHeading() {
      return this.generatedHtml.indexOf("<h") === 0
    },
    listItemClass() {
      let levelModifier = this.isSecondaryLevel
        ? " ordered-list-item--additional-space"
        : ""
      let firstItemSpace = this.block.spaciousFirstLevelList
        ? " ordered-list-item--additional-primary-space"
        : ""
      let headerModifier = this.isHeading ? " ordered-list-item--header" : ""
      return (
        "ordered-list-item" + headerModifier + levelModifier + firstItemSpace
      )
    },
    numberClass() {
      const headerClass = this.isHeading ? this.generatedHtml.substr(1, 2) : ""
      const colourClass =
        this.block.numberingColour && this.block.numberingColour.length
          ? " ordered-list-item__number--" + this.block.numberingColour
          : ""
      const textSizeClass =
        this.block.numberingSize && this.block.numberingSize.length
          ? " ordered-list-item__number--" + this.block.numberingSize
          : ""

      return (
        "ordered-list-item__number " + headerClass + colourClass + textSizeClass
      )
    },
    generatedHtml() {
      let text = ""

      if (typeof this.block.title === "string") {
        text = this.block.title
      } else {
        const resolver = new RichTextResolver()
        text = resolver.render(this.block.title)
      }
      return text
    }
  }
}
</script>

<style lang="scss">
.ordered-list .ordered-list-item--additional-primary-space {
  @include lg {
    margin-bottom: $spacing-6;
  }
}

.ordered-list-item {
  &--header {
    margin-top: $spacing-8;
  }

  li.ordered-list-item--additional-space {
    margin-bottom: $spacing-4;

    @include lg {
      margin-bottom: $spacing-7;
    }
  }

  &__title-wrapper {
    display: flex;
  }

  &__icon {
    vertical-align: middle;
  }

  &__number {
    font-variant-numeric: tabular-nums;
    display: inline-block;
    margin-right: $spacing-2;

    @include lg {
      margin-right: $spacing-4;
    }

    &--yellow400 {
      color: $yellow-400;
    }
    &--large {
      font-size: $font-size-7;
      font-weight: $weight-medium;
    }
  }

  &__title {
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    * {
      display: inline-block;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: $spacing-5;
    }
  }

  ol.ordered-list-item__sublist {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $spacing-4;

    @include lg {
      margin-top: $spacing-2;
      margin-left: $spacing-6;
    }
  }
}
</style>
