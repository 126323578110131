<template>
  <div id="app">
    <AppHeader
      v-if="headerContent"
      :logo-image="headerContent.logoImage"
      :user-status="userStatus"
      :log-in-link="headerContent.logInLink[0]"
      :sign-out-link="headerContent.signOutLink[0]"
      :nav="isLinkedToMarketingSite ? headerNavItems : headerContent.links"
      :deployment-url="vercelUrl"
      @logout="logout"
    />
    <GlobalBanner
      v-if="globalBannerContent"
      :enabled="globalBannerContent.showGlobalBanner"
      :heading="globalBannerContent.copy"
      :variant="globalBannerContent.variant"
      :action-button="globalBannerContent.actionButton"
      :banner-image="globalBannerContent.bannerImage"
      :pages-to-show-on="globalBannerContent.pagesToShowOn"
    />
    <main class="page-content">
      <router-view />
    </main>
    <AppFooter
      v-if="footerContent"
      v-editable="footerContent"
      class="app-footer"
      :support-links="
        isLinkedToMarketingSite ? footerNavItems[0] : footerContent.nav[0]
      "
      :company-links="
        isLinkedToMarketingSite ? footerNavItems[1] : footerContent.nav[1]
      "
      :social-links="
        isLinkedToMarketingSite ? footerNavItems[2] : footerContent.nav[2]
      "
      :company-info="footerContent.companyInfo"
      :copyright="footerContent.copyright"
      @reopenCookieModal="reopenCookieModal"
    />
    <template
      v-if="
        cookieBannerContent &&
        cookieModalContent &&
        !isThirdPartyCookieConsentEnabled
      "
    >
      <CookieManager
        ref="CookieManager"
        :banner-text="cookieBannerContent.bannerText[0].textContent"
        :accept-all-button="cookieBannerContent.buttons[0]"
        :manage-cookies-button="cookieBannerContent.buttons[1]"
        :has-manage-cookies="true"
        :modal-title="cookieModalContent.modalTitle"
        :modal-sub-title="cookieModalContent.modalSubTitle"
        :accept-button="cookieModalContent.acceptButton"
        :save-button="cookieModalContent.saveButton"
        :modal-description="cookieModalContent.modalDescription[0].textContent"
        :necessary-description="
          cookieModalContent.necessaryDescription[0].textContent
        "
        :performance-description="
          cookieModalContent.performanceDescription[0].textContent
        "
        :functional-description="
          cookieModalContent.functionalDescription[0].textContent
        "
        :advertising-description="
          cookieModalContent.advertisingDescription[0].textContent
        "
      >
      </CookieManager>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { defineComponent } from "vue"
import AppHeader from "@soenergy/frontend-library/src/components/AppHeader"
import AppFooter from "@soenergy/frontend-library/src/components/AppFooter"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import CookieManager from "@soenergy/frontend-library/src/components/CookieBanner/CookieManager"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"

import cookieModalContent from "soenergy-cms-loader!?path=common/cookie-modal"
import cookieBannerContent from "soenergy-cms-loader!?path=common/cookie-banner"
import footerContent from "soenergy-cms-loader!?path=layout/footer"

import headerContent from "soenergy-cms-loader!?path=solar/solar-header"
import globalBannerContent from "soenergy-cms-loader!?path=solar/global-banner"

import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"
import CalendlyService from "@soenergy/frontend-library/src/services/CalendlyService"

import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import GlobalBanner from "@soenergy/frontend-library/src/components/GlobalBanner"
import { clearUser } from "@soenergy/frontend-library/src/services/datadog"
import { headerNavItems } from "@/config/headerNavItems"
import { footerNavItems } from "@soenergy/frontend-library/src/config/footerNavItems"
import UtmService from "./services/UtmService"

export default defineComponent({
  name: "App",
  components: {
    AppFooter,
    AppHeader,
    CookieManager,
    GlobalBanner,
  },
  mixins: [
    cmsPreviewMixin({ story: footerContent, name: "footerContent" }),
    cmsPreviewMixin({ story: cookieModalContent, name: "cookieModalContent" }),
    cmsPreviewMixin({
      story: cookieBannerContent,
      name: "cookieBannerContent",
    }),
    cmsPreviewMixin({ story: headerContent, name: "headerContent" }),
    cmsPreviewMixin({
      story: globalBannerContent,
      name: "globalBannerContent",
    }),
  ],
  provide() {
    return {
      currentProject: PROJECTS.Solar,
    }
  },
  data() {
    return {
      isThirdPartyCookieConsentEnabled:
        process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true",
      vercelUrl: process.env.VERCEL_URL,
      isLinkedToMarketingSite:
        process.env.VUE_APP_LINKED_TO_MARKETING_SITE === "true",
      headerNavItems,
      footerNavItems,
    }
  },
  computed: {
    ...mapState({
      userStatus: (state) => state.userStatus.userStatus,
    }),
  },
  mounted() {
    UtmService.init(this.$cookies)
    CalendlyService.initWidget()
    UserStatusService.getUserStatusWithToken()
  },
  methods: {
    reopenCookieModal() {
      if (this.isThirdPartyCookieConsentEnabled) {
        CookieConsentPlugin.openPreferencesModal()
      } else {
        this.$refs.CookieManager.showModal()
      }
    },
    async logout() {
      await AuthenticationService.logout(true)
      window.location.assign(process.env.VUE_APP_SOLAR_SITE_URL)
      clearUser()
    },
  },
})
</script>

<style lang="scss">
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.page-content {
  flex: 1;
  flex-basis: auto; // Fix for IE
  display: flex;
  flex-direction: column;
}
</style>
