import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6298dc70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "solar-packages"
}
const _hoisted_3 = { class: "solar-packages__header" }
const _hoisted_4 = { class: "h4 solar-packages__title" }
const _hoisted_5 = { class: "solar-packages__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pageContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.pageContent.title), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.pageContent.description), 1)
          ]),
          _createVNode(_component_Carousel, {
            cards: _ctx.pageContent.packages,
            copy: {},
            "no-padding": "",
            "buttons-mobile": "",
            margins: "small",
            class: "solar-packages__carousel",
            onGetQuote: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next($event)))
          }, null, 8, ["cards"])
        ]))
      : _createCommentVNode("", true)
  ]))
}