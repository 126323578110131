import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { Page } from "@/router/types"

export default async (to, from, next) => {
  const isAuthenticated =
    await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()
  const getAQuoteCheck = to.meta.getAQuoteCheck

  if (getAQuoteCheck) {
    if (isAuthenticated) {
      return next({ name: Page.QuoteCustomerDetails })
    }
    if (!isAuthenticated) {
      return next({ name: Page.EnterPostcode })
    }
  }
  next()
}
