const formatAccountsAddress = (accountData) => {
  const formatAddress = (data) => {
    data.longAddress = Object.entries(data.address)
      .slice(0, 4)
      .filter((entry) => entry[1])
      .map((entry) => entry[1])
      .join(", ")
    return data
  }
  if (accountData.length) {
    accountData.map((data) => {
      formatAddress(data)
    })
  }
  return accountData
}

export default formatAccountsAddress
