<template>
  <div class="quote-container">
    <form class="quote" @submit.prevent="validatePostcode">
      <div class="quote__input-wrapper">
        <input
          id="postcode"
          v-model.trim="quotePostcode"
          type="text"
          class="quote__input"
          :class="{
            'quote__input-background-grey': backgroundColor === 'grey',
            'quote__input--border': hasBorder,
            'quote__input-error':
              showInvalidMessage || (showInputMessage && !quotePostcode)
          }"
          :change="validatePostcode"
          aria-describedby="postcodeError"
          :aria-invalid="showInputMessage || null"
          data-testid="postcode-input"
        />
        <label
          class="quote__label"
          :class="{
            'quote__input-completed': quotePostcode
          }"
          for="postcode"
          >Postcode
        </label>
      </div>
      <AppButton
        type="submit"
        class="quote__button"
        :class="{ 'quote__button--border': hasBorder }"
        :variant="variant"
        :loading="loading"
        size="large"
        data-testid="search-address-btn"
      >
        {{ buttonLabel }}
      </AppButton>
    </form>
    <div v-if="errorMessage" class="form-group__invalid-feedback" role="alert">
      {{ errorMessage }}
    </div>
    <FeedbackDialog
      v-if="
        (showInputMessage && !quotePostcode) ||
          (showInvalidMessage && quotePostcode)
      "
      id="postcodeError"
      variant="negative"
      :message="
        !quotePostcode
          ? `Please enter a postcode to search for address`
          : `Please use the correct UK postcode format`
      "
      aria-live="assertive"
      role="alert"
    />
  </div>
</template>

<script>
import isValidUkPostcode from "../helpers/isValidUkPostcode"
import AppButton from "./AppButton"
import FeedbackDialog from "./FeedbackDialog"

export default {
  components: {
    AppButton,
    FeedbackDialog
  },
  props: {
    variant: {
      type: String,
      default: null
    },
    buttonLabel: {
      type: String,
      default: "Get Quote"
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    postcode: {
      type: String,
      default: ""
    },
    errorMessage: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      quotePostcode: "",
      showInputMessage: false,
      showInvalidMessage: false,
      postcodeTopPosition: false
    }
  },
  watch: {
    postcode(value) {
      if (value) this.quotePostcode = value
    }
  },
  mounted() {
    this.quotePostcode = this.postcode
  },
  methods: {
    validatePostcode() {
      let data = {
        customerPostcode: this.quotePostcode
      }
      if (!this.quotePostcode) {
        this.showInputMessage = true
      } else {
        if (isValidUkPostcode(this.quotePostcode)) {
          this.$emit("postcode-is-valid", data)
          this.showInvalidMessage = false
        } else {
          this.showInvalidMessage = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-container {
  max-width: 342px;
}

.quote {
  height: $size-2;
  position: relative;
  border-radius: 4px;
  margin: 0;
  background: $day;
  display: flex;

  &__input-wrapper {
    z-index: 1;
    width: 100%;
  }

  &__input {
    outline: none;
    border: 0;
    border-radius: 4px 0 0 4px;
    font-size: $size-6;
    height: 100%;
    width: 100%;
    padding-left: $spacing-4;
    padding-top: $spacing-2;
    background: none;

    &:focus:not(:active) {
      box-shadow: 0 0 0 0.125em $focus;
      z-index: 1;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px $day;
  }

  &__input--border {
    border-top: 1px solid $night-light;
    border-left: 1px solid $night-light;
    border-bottom: 1px solid $night-light;
  }

  &__input-error {
    border: 2px solid $fire-dark;
  }

  &__input-background-grey {
    background-color: #f8f8f8;
  }

  &__input:focus ~ .quote__label {
    display: block;
    top: 0;
    left: $spacing-2;
    font-size: $size-7;
    color: $night;
  }

  &__label {
    position: absolute;
    left: $spacing-4;
    top: $size-8;
    font-size: 1.5rem;
    transition: all 0.5s ease;
    pointer-events: none;
    z-index: 1;
    color: $night;
  }

  &__input-completed {
    top: 0;
    left: $spacing-2;
    font-size: $size-7;
  }

  &__button {
    min-height: 100%;
    border-radius: 0 4px 4px 0;

    &--border {
      border: 1px solid $night-light;
    }
  }
}

.empty-error {
  font-size: $size-7;
  line-height: $line-height-2;
  border: 2px solid $fire;
  border-radius: 4px;
  margin-top: $spacing-2;
  position: relative;
  background: $error;

  &__message-container {
    padding: $section-horizontal-padding $spacing-2;

    @include xs {
      padding: $section-horizontal-padding $spacing-2
        $section-horizontal-padding $section-horizontal-padding;
    }
  }

  &__icon {
    font-size: $size-7;
    vertical-align: bottom;
    font-weight: $weight-bold;

    @include xs {
      margin-right: $spacing-2;
      font-size: $size-6;
      vertical-align: middle;
    }
  }
}
</style>
